<form [formGroup]="form" [autocomplete]="false" class="res-form">
    <div class="res-row">
        <ts-h4 [weight]="600">{{'orders-list.data' | translate}}</ts-h4>
    </div>    
    <div class="res-row">
        <div class="res-column">
            <ts-text-box primaryText="{{'orders-list.columns.code' | translate}}"
                         placeholderText="{{'orders-list.columns.code' | translate}}" 
                         formControlName="code" [enabled]="mode == 'new'" [showErrors]="true" width="300px"></ts-text-box>
            <ts-select   *ngIf="resource.resource == ResourceType.workCenter" [data]="selectDepartments" primaryText="{{'resources.department' | translate}}"
                         placeholderText="{{'resources.department' | translate}}" [value]="f.department.value" 
                         formControlName="department" [showErrors]="true" width="300px"></ts-select>
        </div>
        <div class="res-column">
            <ts-text-box primaryText="{{'resources.description' | translate}}"
                         placeholderText="{{'resources.description' | translate}}" 
                         formControlName="description" [showErrors]="true" width="300px"></ts-text-box>
        </div>        
    </div>
    <div class="device-list">
        <ts-tree-list [data]="allocableDevices" [columns]="columns" key="id" parentKey=null 
        (selectionChanged)="onSelectionChange()"
        actionsColumnCaption="" groupedActionsTooltipText="" [selectionEnabled]="true" 
        emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
        emptyStateSecondaryText="" emptyStateSecondaryLink="" [enablePaging]="false"
        [showSearchBar]="false" [hideActionsColumn]="true" [showRowLines]="true"
        [showBorders]="false" [allowRowDeleting]="false" [allowRowInserting]="false"
        [allowRowUpdating]="false" [wordWrapEnabled]="true" [allowColumnReordering]="false">
            <ts-tree-list-scrolling [mode]="'standard'"></ts-tree-list-scrolling>
            <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data >
            </ng-template>                        
        </ts-tree-list>
    </div>
</form>