<span *ngIf="tplKey === 'decimalType'">
    <span>{{data?.decimal}}</span>
    <span class="secondary-text">{{decimalRepresentation}}</span>
</span>
<span *ngIf="tplKey === 'actionsType'">
    <ts-tree-list-button icon="pencil" title="{{ 'productionUnits.actions.edit' | translate }}"
                         (click)="onEditClicked(data)"></ts-tree-list-button>
    <ts-tree-list-button icon="trash-alt" [isDanger]="true" title="{{ 'productionUnits.actions.delete' | translate }}"
                         (click)="onDeleteClicked(data)"></ts-tree-list-button>
</span>
