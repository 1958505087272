<div class="qa-dialog">
    <div class="title-container">
        <h3>{{ key | translate}}</h3>
        <i class="fa fa-fw fa-times remove-description" (click)="closeDialog()"></i>
    </div>

    <div class="filters" *ngIf="data.needFilterBar">
        <mat-form-field>
            <input matInput type="text" [(ngModel)]="codeSearch" (ngModelChange)="filter()"
                placeholder="{{'products.filterCode' | translate}}">
        </mat-form-field>
        <button mat-mini-fab (click)="filterReset()">
            <i class="fa fa-times"></i>
        </button>
    </div>

    <div class="data-table table-label">
        <div class="datatable-header">
            <div class="datatable-header-cell">
                <div clas="datatable-header-cell-label">
                </div>
            </div>
        </div>
        <div class="datatable-body">
            <div class="datatable-body-row" *ngFor="let row of values">
                <div class="datatable-body-cell">
                    <div class="datatable-body-cell-label">
                        <div>
                            <span>{{ row }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-button">
        <button mat-raised-button (click)="closeDialog()">{{'control.dialog.back' | translate}}</button>
    </div>
</div>