<div class="task-dialog">
    <div class="title-container">
        <h3 *ngIf="!data.isDuplicate && !data.isEdit">{{'control.dialog.title' | translate}}</h3>
        <h3 *ngIf="data.isDuplicate && !data.isEdit">{{'control.dialog.title-duplicate' | translate}}</h3>
        <h3 *ngIf="!data.isDuplicate && data.isEdit">{{'control.dialog.title-edit' | translate}}</h3>
        <i class="fa fa-fw fa-times remove-description" (click)="closeDialog()"></i>
    </div>
    <div class="select-action" *ngIf="selectAction">
        <div class="label-text">
            <span>{{'control.dialog.add-new' | translate}}</span>
        </div>
        <div class="footer-button">
            <button mat-raised-button (click)="addNewTask()">{{'control.dialog.create-new' | translate}}</button>
            <button mat-raised-button (click)="chooseExisting()">{{'control.dialog.use-old' | translate}}</button>
        </div>
    </div>

    <div class="copy-element" *ngIf="useExisting && !selectAction">

        <div class="data-table">
            <div class="datatable-header">
                <div class="datatable-header-cell">
                    <div clas="datatable-header-cell-label">
                        {{'control.dialog.columns.description' | translate}}
                    </div>
                </div>
                <div class="datatable-header-cell">
                    <div clas="datatable-header-cell-label">
                        {{'control.dialog.columns.closedQuestion' | translate }}
                    </div>
                </div>
                <div class="datatable-header-cell">
                    <div clas="datatable-header-cell-label">
                        {{'control.dialog.columns.openQuestion' | translate}}
                    </div>
                </div>
                <div class="datatable-header-cell">
                    <div clas="datatable-header-cell-label">
                        {{'control.dialog.columns.docType' | translate}}
                    </div>
                </div>
            </div>
            <div class="datatable-body">
                <div class="datatable-body-row" *ngFor="let row of filteredTask" (click)="onSelectedTask(row.id)">
                    <div class="datatable-body-cell">
                        <div class="datatable-body-cell-label">
                            <span class="prod-label">{{row.description}}</span>
                        </div>
                    </div>
                    <div class="datatable-body-cell">
                        <div class="datatable-body-cell-label" *ngIf="row.closedQuestion">
                            <i class="fa fa-check-circle-o"></i>
                        </div>
                    </div>
                    <div class="datatable-body-cell">
                        <div class="datatable-body-cell-label" *ngIf="row.unitId || (row.TasksParameters && row.TasksParameters.length)">
                            <i class="fa fa-check-circle-o"></i>
                        </div>
                    </div>
                    <div class="datatable-body-cell">
                        <div class="datatable-body-cell-label" *ngIf="row.docLink || row.docText !== '' || row.docText">
                            <i class="fa fa-check-circle-o"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-button">
            <button mat-raised-button (click)="goBack()">{{'control.dialog.back' | translate}}</button>
        </div>

    </div>

    <div *ngIf="!selectAction && !useExisting">
        <div class="task-container">
            <mat-form-field class="question-container">
                <input matInput #question placeholder="{{'control.dialog.input-question' | translate}}" type="text"
                    [(ngModel)]="currentTask.description">
                <i matSuffix class="fa fa-fw fa-times remove-description" (click)="_removeDescription()"></i>
            </mat-form-field>
            <span class="section-label">{{'control.dialog.answer-type' | translate}}</span>
            <div class="select-container task-type-container">
                <mat-checkbox class="task-option" [(ngModel)]="currentTask.closedQuestion" id="closed-question">
                    {{'control.dialog.closed-question' | translate}} </mat-checkbox>
                
                <div class="values-section">
                    <mat-checkbox class="task-option" [(ngModel)]="taskWithValues" id="open-question" (ngModelChange)="taskWithValuesChanged()">
                        {{'control.dialog.open-question' | translate}}</mat-checkbox>
                    <div *ngIf="taskWithValues" style="width: 100%;">
                        <div *ngFor="let parameter of currentTask.TasksParameters; index as parameterIndex"
                            class="value-options-container">
                            <div class="value-description-containter">
                                <div class="remove-value-container">
                                    <i class="fa fa-fw fa-times remove-value-icon" *ngIf="parameterIndex > 0" (click)="removeValue(parameterIndex)"></i>
                                </div>
                                <mat-form-field class="description">
                                    <input matInput placeholder="{{'control.dialog.value-description' | translate}}" type="text"
                                        [(ngModel)]="parameter.description">
                                </mat-form-field>
                                <mat-form-field class="unit">
                                    <mat-label>{{'control.dialog.unit' | translate}}</mat-label>
                                    <mat-select [(ngModel)]="parameter.unitId">
                                        <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.description}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- <mat-form-field class="unit">
                                    <input matInput placeholder="Unità di misura" type="text"
                                        [(ngModel)]="parameter.unitId">
                                </mat-form-field> -->
                            </div>
                            <div class="value-parameter-containter">
                                <mat-checkbox [(ngModel)]="parameter.insertThresholds" class="parameter-question"
                                    (ngModelChange)="insertThresholdsChanged(parameterIndex)">
                                    {{'control.dialog.insert-threshold' | translate}}
                                </mat-checkbox>
                                <mat-form-field class="parameter" [ngClass]="{'hide': !parameter.insertThresholds}">
                                    <input matInput placeholder="{{'control.dialog.min-threshold' | translate}}" type="number"
                                        [(ngModel)]="parameter.minThreshold">
                                </mat-form-field>
                                <mat-form-field class="parameter" [ngClass]="{'hide': !parameter.insertThresholds}">
                                    <input matInput placeholder="{{'control.dialog.target-value' | translate}}" type="number"
                                        [(ngModel)]="parameter.targetValue">
                                </mat-form-field>
                                <mat-form-field class="parameter" [ngClass]="{'hide': !parameter.insertThresholds}">
                                    <input matInput placeholder="{{'control.dialog.max-threshold' | translate}}" type="number"
                                        [(ngModel)]="parameter.maxThreshold">
                                </mat-form-field>
                                <mat-form-field class="popup-message" *ngIf="parameter.insertThresholds">
                                    <input matInput placeholder="{{'control.dialog.info-popup' | translate}}" type="text"
                                        [(ngModel)]="parameter.outOfRangeDescription">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="add-value" *ngIf="taskWithValues" (click)="addValue()"><span class="plus-icon">+</span>{{'control.dialog.add-value' | translate}}</p>
            </div>
            <span class="section-label">{{'control.dialog.upload-instructions' | translate}}</span>

            <div class="input-instruction-container">
                <div class="input-instruction-container">
                    <mat-radio-button class="task-option" [value]="isDocText" [checked]="isDocText"
                        (click)="onChangeRadioButton(true)">
                        {{'control.dialog.write-note' | translate}}</mat-radio-button>
                    <mat-radio-button class="task-option" [value]="isDocLink" [checked]="isDocLink"
                        (click)="onChangeRadioButton(false)">
                        {{'control.dialog.from-file' | translate}}</mat-radio-button>
                </div>


                <!-- <button type="button" class="upload-button" mat-raised-button
                    (click)="fileInput.change($event.target.files)" *ngIf="isDocLink"></button> -->
                <div class="wrapper">
                    <input accept="application/pdf" #fileInput type="file" id="file"
                        (change)="handleFileInput($event.target.files)" *ngIf="isDocLink">
                    <label for="file" class="btn-1" *ngIf="isDocLink">upload file</label>
                </div>
            </div>

            <mat-form-field class="instruction-container" *ngIf="!isDocLink && isDocText">
                <textarea matInput placeholder="{{'control.dialog.input-instruction' | translate}}" type="text"
                    [(ngModel)]="currentTask.docText" class="text-area"></textarea>
            </mat-form-field>

            <div class="instruction-container" *ngIf="isDocLink && !isDocText">
                <label class="uploaded-file" *ngIf="isDocLink && isUpdated">
                    <i class="fa fa-file-pdf-o" aria-hidden="true" *ngIf="srcResult.name !== '' "></i>
                    {{srcResult.name}}
                </label>
                <label class="uploaded-file" *ngIf="isDocLink && !isUpdated">
                    <i class="fa fa-file-pdf-o" aria-hidden="true" *ngIf="currentTask.docLink !== '' "></i>
                    {{currentTask.docLink}}
                </label>
                <div class="data-table data-table-document">
                    <div class="datatable-header">
                        <div class="datatable-header-cell">
                            <div clas="datatable-header-cell-label">
                                {{'control.dialog.columns.uploaded-instruction' | translate}}
                            </div>
                        </div>
                        <div class="datatable-header-cell">
                            <div clas="datatable-header-cell-label">
                                {{'control.dialog.columns.size' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="datatable-body">
                        <div class="datatable-body-row" *ngFor="let row of docs"
                            (click)="onSelectedDoc(row.originalname)">
                            <div class="datatable-body-cell">
                                <div class="datatable-body-cell-label">
                                    <div>
                                        <span>{{ row.originalname}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="datatable-body-cell">
                                <div class="datatable-body-cell-label">
                                    <span>{{ row.size | filesize}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer-button">
                <button mat-raised-button *ngIf="!data.isDuplicate && !data.isEdit"
                    (click)="goBack()">{{'control.dialog.back' | translate}}
                </button>
                <div class="save-button" (click)="saveTask()"
                    [ngClass]="{'disabled': !checkIfCanSaveTask()}">
                    <i class="fa fa-fw fa-circle-o-notch rotating" *ngIf="isUploading"></i>
                    <span> {{ 'control.dialog.save' | translate}} </span>
                    <i class="fa fa-fw fa-circle-o-notch" style="opacity: 0;" *ngIf="isUploading"></i>
                </div>
            </div>
        </div>

    </div>
</div>