<div class="product-dialog">
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content>
        <p>{{data.message}}</p>
    </div>
    <div mat-dialog-actions>
    
        <button *ngIf="data.applyOne" mat-raised-button (click)="onConfirm(applyOneAction)" tabindex="2">{{data.applyOne}}</button>
        <button *ngIf="!data.applyOne" mat-raised-button  (click)="onConfirm(applyOneAction)" tabindex="2">{{'dialogs.products.applyOne' | translate}}</button>
        
        <button *ngIf="data.applyAll" mat-raised-button  (click)="onConfirm(applyAllAction)" tabindex="3">{{data.applyAll}}</button>
        <button *ngIf="!data.applyAll" mat-raised-button  (click)="onConfirm(applyAllAction)" tabindex="3">{{'dialogs.products.applyAll' | translate}}</button>
        
        <button *ngIf="data.backText" mat-raised-button (click)="onBack()" tabindex="1">{{data.backText}}</button>
        <button *ngIf="!data.backText" mat-raised-button (click)="onBack()" tabindex="1">{{'dialogs.products.back' | translate}}</button>
    </div>
</div>