<span *ngIf="tplKey === 'descriptionType'">
    <ts-tree-list-cell  [cellText]="data?.description" [hyperlinkEnabled]="false"
                       [buttonIsVisible]="false"></ts-tree-list-cell>    
</span>
<ts-tree-list-cell  *ngIf="tplKey === 'resourceType'" [hyperlinkEnabled]="false"
                    [cellText]="resourceString"  [buttonIsVisible]="false">
</ts-tree-list-cell>
<span *ngIf="tplKey === 'actionsType'">
    <ts-tree-list-button icon="pencil" title="{{'orders-list.actions.edit' | translate}}"[disabled]="data?.resource == 1"
                         (click)="onEditClicked(data.id,data.resource)"></ts-tree-list-button>
</span>
<ng-container *ngIf="tplKey === 'selection'">
    <ts-simple-checkbox [value]="data.selected"
                        (valueChange)="onResourceToggle(data.id, $event)"></ts-simple-checkbox>
</ng-container>