import { Component, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { AutocompleteComponent, FontService, SimpleSelectComponent } from '@vapor/angular-ui';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { faPlusCircle, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { ProductionUnitInstance } from '../../../models/productionUnit.model';
import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WorksService } from '../../../services/works.service';

@Component({
    selector: 'order-editor-new-order',
    templateUrl: './order-editor-new-order.component.html',
    styleUrls: ['./order-editor-new-order.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OrderEditorNewOrderComponent implements OnInit {

    @Input() editMode: boolean;

    @Input() step: number;

    @Input() stepList: { currentStep: string, description: string }[];

    @Input() formOrderData: FormGroup;

    @Input() formCycleData: FormGroup;

    @Input() additionalInfoForm: FormGroup;

    @Input() selectStatusOrderData: {id: number, val: string} [] = [];

    @Input() selectResourceTypeData: {id: number, val: string} [] = [];

    @Input() selectResourceData: {id: number, val: string} [] = [];

    @Input() f: any;

    @Input() additionalInfoOpen: boolean = false;

    @Input() toggleAdditionalInfo: () => void;
    
    @Input() keyValueArray: any;

    @Input() phasesArray: any;

    @Input() phasesList: string;

    @Input() sequence: string;

    @Input() companyId: number;

    @Input() plantHasComponentsListModule: boolean;

    @Output() handleNewInfoButtonClickedEvent = new EventEmitter<void>();

    @Output() handleRemoveInfoButtonClickedEvent = new EventEmitter<number>();

    @Output() openComponentsListEvent = new EventEmitter<number>();

    @Output() resourceTypeSelected = new EventEmitter<number>();

    @Output() workIdChangeEvent = new EventEmitter<{ index: number, work: string }>();

    @ViewChild('product', { static: false }) productAutocomplete: AutocompleteComponent;

    @Input() selectedProductProductionUnit: ProductionUnitInstance;

    @ViewChildren('worksSelect') worksAutocompleteList: QueryList<AutocompleteComponent>;

    @ViewChildren('resourcesSelect') resourcesSelectList: QueryList<SimpleSelectComponent>;

    resourceTypeLabel = 'Device';

    customValidationDict = {
        required: 'common.inputErrors.required',
        min: 'common.inputErrors.min'
    };

    private _subscriptions: Subscription[] = [];

    constructor(
        private readonly _font: FontService,
        private readonly _translate: TranslateService,
        private readonly _works: WorksService,
    ) {
        this._font.addIcon(faPlusCircle, faTrashAlt);
    }

    async ngOnInit(): Promise<void> {        
        const translateSubscription =
        this._translate.stream([
          'resources.device'
        ]).subscribe((translations) => {
            this.resourceTypeLabel = translations['resources.device']
        });
        this._subscriptions.push(translateSubscription);
    }

    ngOnDestroy(): void {
        // Unsubscribe all subscriptions to avoid memory leaks
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
            array.splice(index, 1);
        });
    }

    onOpenComponentsList(index: number) {
        this.openComponentsListEvent.emit(index);
    }

    onHandleNewInfoButtonClicked() {
        this.handleNewInfoButtonClickedEvent.emit();
    }

    onHandleRemoveInfoButtonClicked(i) {
        this.handleRemoveInfoButtonClickedEvent.emit(i);
    }

    onResourceTypeChange(data: {id: number, val: string}){
        switch (data?.id) {
            case 1:
                this.resourceTypeLabel = this._translate.instant('resources.device');                
                break;            
            case 2:
                this.resourceTypeLabel = this._translate.instant('resources.department');
                break;
            case 3:
                this.resourceTypeLabel = this._translate.instant('resources.workcenter');                
                break;
            default:
                break;
        }
        this.resourceTypeSelected.emit(data?.id);
    }

    onWorkIdChange(index: number, data: {id: number, val: string}) {
        this.workIdChangeEvent.emit({ index: index, work: data?.val });
    }

}
