<ts-tab-panel [panelTabs]="tabs" [(selectedTab)]="selectedTabId" type="primary" barType="standard" size="small"
    [selectTabOnClick]="true">
</ts-tab-panel>
<ng-container [ngSwitch]="selectedTabId">
    <div *ngSwitchCase="1" class="container">
        <div class="row">
            <ts-h4 [weight]="600">{{'orders-list.group-association.select-group' | translate}}</ts-h4>
        </div>
        <div class="row">
            <ts-tree-list [data]="groupsList" [columns]="columns" key="id" parentKey="groupId" actionsColumnCaption=""
                groupedActionsTooltipText="" emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                emptyStateSecondaryText="" emptyStateSecondaryLink="" [enablePaging]="false" [showSearchBar]="true"
                [hideActionsColumn]="true" [showRowLines]="true" [showBorders]="false" [allowRowDeleting]="false"
                [allowRowInserting]="false" [allowRowUpdating]="false" [wordWrapEnabled]="true">
                <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
                    <ng-container *ngIf="data?.isGroup && tplKey === 'tplRadioSelection'">
                        <ts-simple-radio name="radio" [value]="data" [checked]="selectedGroup?.id === data?.id"
                            (valueChange)="onRadioSelected($event)"></ts-simple-radio>
                    </ng-container>
                    <app-orders-list-cell [tplKey]="tplKey" [data]="data" [level]="level"></app-orders-list-cell>
                </ng-template>
            </ts-tree-list>
        </div>
    </div>
    <div *ngSwitchCase="2" class="orders-container">
        <ts-h4 [weight]="600">{{'orders-list.group-association.add-to-group-title' | translate }}</ts-h4>
        <div class="order-item" *ngFor="let order of selectedOrders; let i = index">
            <ts-sub-rg [weight]="700">{{ i + 1 }}. {{ order.code }}</ts-sub-rg><br>
            <div class="order-data">
                <div class="order-row">
                    <div class="order-info">
                        <ts-sub-rg>{{'orders-list.columns.target' | translate}}</ts-sub-rg>
                    </div>
                    <div class="order-info">
                        <span>{{ 'orders-list.cells.target' | translate:{'target':order.target} }}</span>
                    </div>
                    <div class="order-info">
                        <ts-sub-rg>{{'orders-list.columns.product-description' | translate}}</ts-sub-rg>
                    </div>
                    <div class="order-info">
                        <span>{{order.Product.code}} - {{order.Product.name}}</span>
                    </div>
                </div>
                <div class="order-row">
                    <div class="order-info">
                        <ts-sub-rg>{{'orders-list.columns.date' | translate}}</ts-sub-rg>
                    </div>
                    <div class="order-info">
                        <span>{{ order.date | date:"shortDate" }}</span>
                    </div>
                    <div class="order-info">
                        <ts-sub-rg>{{'orders-list.columns.devices' | translate}}</ts-sub-rg>
                    </div>
                    <div class="order-info">
                        <span>{{ concatDevices(order.Devices) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>