<div class="operators-dialog">
    <div class="title-container">
        <h3>{{'operators.title' | translate}}</h3>
        <i class="fa fa-fw fa-times remove-description" (click)="closeDialog()"></i>
    </div>
  
    <div class="order-core-container">
      <div class="order-core-body">
        <form>
            <div class="first-container">
                <mat-form-field class="input-left">
                    <input matInput type="string" name="name" [(ngModel)]="operator.name"
                        placeholder="{{'users.columns.firstName'| translate}}" />
                </mat-form-field>
                <mat-form-field class="input-right">
                    <input matInput type="string" name="surname" [(ngModel)]="operator.surname"
                        placeholder="{{'users.columns.lastName'| translate}}" />
                </mat-form-field>
                <mat-form-field #deliveryDate class="input-left">
                    <input matInput type="string" name="code" [(ngModel)]="operator.code"
                        placeholder="{{'orders-list.columns.code'| translate}}" />
                </mat-form-field>
                <mat-form-field class="input-right">
                    <mat-label>{{'orders-list.columns.devices' | translate}}</mat-label>
                    <mat-select [compareWith]="compareObjects" [(formControl)]="devicesControl" multiple panelClass="multiselect-panel">
                        <mat-select-trigger>
                        {{devicesControl.value?.length && devicesControl.value.length < devices.length ? devicesControl.value[0].label : ''}}
                        {{devicesControl.value?.length && devicesControl.value.length >= devices.length ? 'All devices' : ''}}
                        <span *ngIf="devicesControl.value?.length > 1 && devicesControl.value?.length < devices.length" class="multiselection">
                            (+{{devicesControl.value.length - 1}} {{devicesControl.value?.length === 2 ? 'other' : 'others'}})
                        </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let device of devices" [value]="device" class="multiselect-option">{{device.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
      </div>
    </div>
    <div class="footer-button">
        <button mat-raised-button (click)="confirm()">
            {{'common.dialog.close' | translate}}
        </button>
        <button [disabled]="!operator.name || !operator.surname" mat-raised-button (click)="confirm('save')">
            {{'common.dialog.save' | translate}}
        </button>
    </div>
  </div>