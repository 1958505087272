<span *ngIf="tplKey === 'tplActions'">
    <ts-tree-list-button icon="window-maximize" title="{{'products.actions.details' | translate}}"
                         (click)="drawerClick(data)"></ts-tree-list-button>
    <ts-tree-list-button icon="pencil" title="{{'products.actions.edit' | translate}}"
                         (click)="pencilClick(data)"></ts-tree-list-button>
    <ts-tree-list-button icon="ellipsis-v" title="{{'products.actions.menu' | translate}}"
                         (click)="ellipsisClick(data)"
                         [tsActionTooltip]="productTooltip"
                         [tsTooltipPosition]="'bottomRight'"
                         [showTooltip]="showEllipsisTooltip"
                         
                         ></ts-tree-list-button>
</span>
<span *ngIf="tplKey === 'tplDevices'">
    <ts-tree-list-cell *ngIf="data?.Devices?.length == 1" [cellText]="data?.Devices[0].label" [hyperlinkEnabled]="false"
                       [buttonIsVisible]="false"></ts-tree-list-cell>
    <ts-tree-list-cell *ngIf="data?.Devices?.length > 1"
                       cellText="{{ 'orders-list.cells.devices' | translate:{ first: data?.Devices[0].label, others: data?.Devices?.length - 1 } }}"
                       [hyperlinkEnabled]="false" [buttonIsVisible]="false"></ts-tree-list-cell>
</span>

<ng-template #productTooltip>
    <div class="tooltip-container">
        <div class="btn-wrapper" (mwlClick)="duplicateProductClick(data)">
            <ts-button type="primary medium white" icon="copy"></ts-button>
            <div style="color: #0077AD; font-size: 16px; font-family: Cairo; font-weight: 500; line-height: 16px; word-wrap: break-word">{{'products.duplicateProduct' | translate}}</div>
        </div>
        <div class="btn-wrapper" (mwlClick)="deleteProductClick(data)">
            <ts-button type="primary medium alert-dark" icon="trash"></ts-button>
            <div style="color: #D92B2C; font-size: 16px; font-family: Cairo; font-weight: 500; line-height: 16px; word-wrap: break-word">{{'products.deleteProduct' | translate}}</div>
        </div>
    </div>
</ng-template>
