<div class="devices-page">
  <ngx-datatable
    [rows]="unipis"
    [columns]="columns"
    [columnMode]="'force'"
    [headerHeight]="80"
    [rowHeight]="60"
    [scrollbarV]="true"
    class="data-table">
  </ngx-datatable>
</div>


<ng-template #newThirdPartyDeviceDialog ngx-datatable-header-template>
    <div class="add-new-element-button" (click)="openThirdPartyDeviceDialog()">
        <i class="fa fa-plus"></i>
        <span> {{ 'devices.addNewThirdParty' | translate}}</span>
    </div>
</ng-template>
<ng-template #newVirtualDeviceDialog ngx-datatable-header-template>
    <div class="add-new-element-button" (click)="openVirtualDeviceDialog()">
        <i class="fa fa-plus"></i>
        <span> {{ 'devices.addNewVirtual' | translate}}</span>
    </div>
</ng-template>


<ng-template #label let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <!-- <mat-form-field>
        <input matInput type="label" [(ngModel)]="row.label" (ngModelChange)="setModified(rowIndex)">
    </mat-form-field> -->
</ng-template>
    
<ng-template #date let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <!-- <mat-form-field>
        <input matInput type="label" [(ngModel)]="row.label" (ngModelChange)="setModified(rowIndex)">
    </mat-form-field> -->
    <p>{{row.createdAt}}</p>
</ng-template>

<ng-template #actions let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
  <button mat-mini-fab class="green-button" (click)="newDialog(row, rowIndex)">
      <mat-icon class="fa fa-plus"></mat-icon>
  </button>
  <!-- <button mat-mini-fab class="red-button" [disabled]="true" (click)="delete(row, rowIndex)">
      <i class="fa fa-trash-o"></i>
  </button> -->
</ng-template>
