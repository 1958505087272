<div class="device-dialog">
  <div class="title-container">
      <h3>{{label}}</h3>
      <i class="fa fa-fw fa-times remove-description" (click)="closeDialog()"></i>
  </div>

  <div class="device-module-container">
    <div class="device-module-body">
        <div class="device-module-option">
            <span>{{ 'companies.deviceModule.performances' | translate }}</span>
            <mat-slide-toggle class="device-module-enable"
            [(ngModel)]="deviceModule.performances"></mat-slide-toggle>
        </div>
        <div class="device-module-option">
            <span>{{ 'companies.deviceModule.orders' | translate }}</span>
            <mat-slide-toggle class="device-module-enable"
            [(ngModel)]="deviceModule.orders"></mat-slide-toggle>
        </div>
        <div class="device-module-option-suboption-container">
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.isOnSatellite">
                    <span>{{ 'companies.orderConfigModule.isOnSatellite' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.autoProgress">
                    <span>{{ 'companies.orderConfigModule.autoProgress' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.validateProgress">
                    <span>{{ 'companies.orderConfigModule.validateProgress' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.validateProgressQuality">
                    <span>{{ 'companies.orderConfigModule.validateProgressQuality' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.workProcess">
                    <span>{{ 'companies.orderConfigModule.workProcess' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.deltaTarget">
                    <span>{{ 'companies.orderConfigModule.deltaTarget' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.targetScrapsDiff">
                    <span>{{ 'companies.orderConfigModule.targetScrapsDiff' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.showOrderList">
                    <span>{{ 'companies.orderConfigModule.showOrderList' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.multiOrder">
                    <span>{{ 'companies.orderConfigModule.multiOrder' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.batch">
                    <span>{{ 'companies.orderConfigModule.batch' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.batchRawMaterial">
                    <span>{{ 'companies.orderConfigModule.batchRawMaterial' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.scanMaterial">
                    <span>{{ 'companies.orderConfigModule.scanMaterial' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.pdfDocs">
                    <span>{{ 'companies.orderConfigModule.pdfDocs' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="device-module-option-suboption">
                <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.endOrder">
                    <span>{{ 'companies.orderConfigModule.endOrder' | translate }}</span>
                </mat-checkbox>
            </div>
        </div>
        <div class="device-module-option-suboption">
            <mat-checkbox class="target-time-checkbox" [disabled]="!deviceModule?.orders" [(ngModel)]="deviceModule.OrderModuleConfig.startOrder">
                <span>{{ 'companies.orderConfigModule.startOrder' | translate }}</span>
            </mat-checkbox>
        </div>
        <div class="device-module-option">
            <span>{{ 'companies.deviceModule.qualityControls' | translate }}</span>
            <mat-slide-toggle class="device-module-enable"
            [(ngModel)]="deviceModule.qualityControls"></mat-slide-toggle>
        </div>
        <div class="device-module-option">
            <span>{{ 'companies.deviceModule.rawMaterial' | translate }}</span>
            <mat-slide-toggle class="device-module-enable"
            [(ngModel)]="deviceModule.rawMaterial"></mat-slide-toggle>
        </div>
        <div class="device-module-option">
            <span>{{ 'companies.deviceModule.parameters' | translate }}</span>
            <mat-slide-toggle class="device-module-enable"
            [(ngModel)]="deviceModule.parameters"></mat-slide-toggle>
        </div>
        <div class="device-module-option">
            <span>{{ 'companies.deviceModule.operatorsLogin' | translate }}</span>
            <mat-slide-toggle class="device-module-enable"
            [(ngModel)]="deviceModule.operatorsLogin"></mat-slide-toggle>
        </div>
    </div>
  </div>
  <div class="footer-button">
      <button mat-raised-button (click)="closeDialog()">Annulla
      </button>
      <button mat-raised-button (click)="deleteDeviceModule()" [disabled]="!deviceModule?.id">Reset Config</button>
      <button mat-raised-button (click)="save()">Salva
      </button>
  </div>
</div>