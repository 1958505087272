import { PlantInstance } from "./plant.model";

export interface WorkInstance {
  id?: number;
  code?: string;
  description?: string;
  companyId?: number;
  managed?: boolean;
  default?: boolean;
  deleted?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface WorksPlantsViewInstance {
  id?: number;
  code?: string;
  description?: string;
  companyId?: number;
  plantId?: number;
  workIdPlantId?: string;
  default?: boolean;
  managed?: boolean;
  deleted?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  Plant?: PlantInstance;
  WorksPlant?: WorksPlantsInstance;
  Work?: WorkInstance;
}

export interface WorksPlantsInstance {
  id?: number;
  workId?: number;
  plantId?: number;
  description?: string;
  deleted?: boolean;
  workIdPlantId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  Plant?: PlantInstance;
  Work?: WorkInstance;
}

export interface ApiWorkData { 
  companyId?: number, 
  description?: string, 
  code?: string, 
  managed?: boolean, 
  default?: boolean 
}

export interface ApiWorkPlantData { 
  workId: number, 
  plantId: number, 
  description: string, 
  deleted?: boolean 
}