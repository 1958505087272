import { DeviceInstance } from './device.model';
import { ProductionUnitInstance } from './productionUnit.model';
import { ProductsFamilyInstance } from './productsFamily.model';
import { Resource } from './resources.model';

export interface ProductInstance {
    id: number;
    name: string;
    description?: string;
    code: string;
    companyId: number;
    plantId?: number;
    workProcessId?: number;
    Devices: DeviceInstance[];
    resumeProductionProductsThreshold?: number;
    stopProductsThreshold?: number;
    stopProductsThresholdSec?: number;
    idealPiecesPerMinutesSpeed?: number;
    inputMultiplier?: number;
    multiplier?: number;
    scrapsMultiplier?: number;
    defaultQuantity?: number;
    divider?: number;
    enabled: boolean;
    type?: ProductType;
    productSupplyType?: ProductSupplyType;
    familyId?: number;
    unitId?: number;
    supplyType?: number;
    ProductionUnit?: ProductionUnitInstance;
    ProductsFamily?: ProductsFamilyInstance;
    measureUnit?: string;
    family?: string;
    jsonInfo?: string;
    resources?: Resource [];
    variationGroupId1?: number;
    variationGroupId2?: number;
    variationGroupId3?: number;    
}

export interface Product {
    id?: number,
    companyId?: number;
    code: string;
    description: string;
    familyId: number;
    unitId: number;
    type?: ProductType;
    stopProductsThreshold?: number;
    stopProductsThresholdSec?: number;
    idealPiecesPerMinutesSpeed?: number;
    inputMultiplier?: number;
    multiplier?: number;
    scrapsMultiplier?: number;
    defaultQuantity?: number;
    divider?: number;
    productSupplyType?: ProductSupplyType;
    supplyType?: number;
    ProductionUnit?: ProductionUnitInstance;
    ProductsFamily?: ProductsFamilyInstance;
    resources?: Resource [];
    jsonInfo?: Object;
    Devices: DeviceInstance[];
    variationGroupId1?: number;
    variationGroupId2?: number;
    variationGroupId3?: number;
}

export interface ProductInterface extends ProductInstance {
    deviceLabels?: string[];
}

export enum ProductType {
    product = 1,
    semiFinished = 2,
    rawMaterial = 3,
}

export enum ProductSupplyType {
    make = 1,
    buy = 2,
}