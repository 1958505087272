<div class="loader-container" *ngIf="lockRequest">
    <img src={{getLoaderSrc()}}>
</div>
<div class="control-page" *ngIf="!lockRequest">

    <div class="input-containter">
        <div class="container-fixed" [class.fixed]="fixed">
            <mat-form-field class="description-container" *ngIf="control">
                <mat-label>{{'control.description' | translate}}</mat-label>
                <input matInput #description class="title" type="text" [(ngModel)]="control.description"
                    placeholder="{{'control.insert-description' | translate}}">
                <i matSuffix class="fa fa-fw fa-times remove-description" (click)="removeDescription()"></i>
                <mat-hint>{{'control.insert-description' | translate}}</mat-hint>
            </mat-form-field>
            <div class="save-button" (click)="updateControl()" [ngClass]="{'disabled': control.description === ''}">
                <span> {{ 'control.save' | translate}}</span>
            </div>
        </div>
        <div class="container container-subtitle">
            <span class="container-title-scheduling">{{'control.scheduling' | translate}}</span>
        </div>
        <div class="container">
            <div class="scheduler-main-container" *ngIf="controlSchedulings && controlSchedulings.length">
                <div class="scheduler-wrapper" *ngFor="let scheduling of controlSchedulings">
                    <mat-form-field class="scheduler-container">
                        <mat-label>{{'control.time-scheduling' | translate}}</mat-label>
                        <mat-select [(ngModel)]="scheduling.optionId"
                            (selectionChange)="changeOption(controlSchedulings.indexOf(scheduling))">
                            <mat-option *ngFor="let selectScheduling of selectSchedulings"
                                [value]="selectScheduling.id">
                                {{selectScheduling.description | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="quantity-container" *ngIf="isQuantityRequired(scheduling)">
                        <mat-label *ngIf="scheduling.optionId === 2 || scheduling.optionId === 3">
                            {{'control.scheduling-number-pieces' | translate}}</mat-label>
                        <mat-label *ngIf="scheduling.optionId === 5 || scheduling.optionId === 4">
                            {{'control.scheduling-number-minutes' | translate}}</mat-label>
                        <mat-label *ngIf="scheduling.optionId === 6">
                            {{'control.scheduling-number-quantity' | translate}}</mat-label>
                        <input matInput class="title" type="number" min="1" [(ngModel)]="scheduling.quantity">
                        <!-- <mat-hint>{{'control.insert-number-pieces' | translate}}</mat-hint> -->
                    </mat-form-field>

                    <!-- <mat-form-field class="quantity-container" *ngIf="errors && scheduling.optionId ===6">
                        <mat-label>{{'control.stops' | translate}}</mat-label>
                        <mat-select [(ngModel)]="scheduling.errorId">
                            <mat-option *ngFor="let error of errors" [value]="error.id">
                                {{error.text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> -->

                    <mat-form-field class="quantity-container" *ngIf="errors && scheduling.optionId ===6">
                        <mat-label>{{'control.stops' | translate}}</mat-label>
                        <mat-select [(ngModel)]="scheduling.errorId" (selectionChange)="selectStopReason($event)">
                            <mat-optgroup *ngFor="let tag of errorGroupsList" value="item" label="{{tag.name}}">
                                <mat-option *ngFor="let error of tag.Errors" [value]="error.id">
                                    <span class="{{error.errorTypeString}}">{{error.text}}</span>
                                </mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="quantity-container" *ngIf="wokrProcessPhasesComputed?.length && scheduling.optionId ===7">
                        <mat-label>{{'control.phases' | translate}}</mat-label>
                        <mat-select [(ngModel)]="scheduling.phaseId">
                            <mat-option *ngFor="let wpp of wokrProcessPhasesComputed" [value]="wpp.phaseId">
                                <span>{{wpp.value}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- <mat-form-field class="repeat-container" *ngIf="isRepeatRequired(scheduling)">
                        <mat-label>{{'control.scheduling-repeat' | translate}}</mat-label>
                        <input matInput class="title" type="number" [(ngModel)]="scheduling.repeat" min="1">
                    </mat-form-field> -->

                    <div class="icon-container">
                        <i class="fa fa-fw fa-times remove-description"
                        (click)="deleteControlScheduling(scheduling)"></i>
                    </div>
                    <p *ngIf="scheduling.repeat > 1" class="repeat-info-label">
                        <span class="attention-span">{{'quality-controls.scheduling.attention' | translate}}:</span> {{'quality-controls.scheduling.repeat-info-label' | translate}}
                    </p>
                </div>
                <div class="scheduler-container">
                    <button class="add-new-element-button scheduling-button" (click)="addNewControlScheduling()"
                        [ngClass]="{'disabled': controlSchedulings.length >= 3}">
                        <i class="fa fa-plus" text-align="right"></i>
                        {{'control.add-scheduling' | translate}}
                    </button>

                </div>
            </div>
        </div>
        <div class="container">
            <span class="container-title">{{'control.device' | translate}}</span>
            <span class="container-title">{{'control.product' | translate}}</span>
        </div>
        <div class="container">
            <div class="device-wrapper" [formGroup]="controlDeviceForm">
                <mat-form-field class="device-container">
                    <input type="text" placeholder="{{'control.select-device' | translate}}" aria-label="Number"
                        matInput [matAutocomplete]="deviceAutocomplete" formControlName="devices">
                    <mat-hint>{{'control.insert-devices' | translate}}</mat-hint>
                    <mat-autocomplete #deviceAutocomplete="matAutocomplete">
                        <mat-option (click)="addAllDevices()">
                            {{'control.select-all-device' | translate}}
                            <i class="fa fa-plus" text-align="right"></i>
                        </mat-option>
                        <mat-option *ngFor="let device of filteredDeviceOptions | async" (click)="addDevice(device.id)"
                            class="device-list">
                            <span>{{device.label}}</span>
                            <i class="fa fa-plus" text-align="right"></i>
                        </mat-option>

                    </mat-autocomplete>
                </mat-form-field>

                <div class="tag-device" *ngIf="selectedDevices.length !== devices.length">
                    <div class="add-new-element-button single-tag" *ngFor="let selectedDevice of selectedDevices">
                        <mat-icon matSuffix class="fa fa-fw fa-times" (click)="removeDevice(selectedDevice.id)"
                            [ngClass]="{'disabled': blocDevice}">
                        </mat-icon>
                        {{selectedDevice.label}}
                    </div>
                </div>
                <div class="tag-device" *ngIf="selectedDevices.length === devices.length">
                    <div class="add-new-element-button single-tag" *ngIf="devices.length > 1">
                        <mat-icon matSuffix class="fa fa-fw fa-times" (click)="clearAllDevices()"
                            [ngClass]="{'disabled': blocDevice}">
                        </mat-icon>
                        {{'control.all-devices' | translate}}
                    </div>
                    <div class="add-new-element-button single-tag" *ngIf="devices.length === 1">
                        <mat-icon matSuffix class="fa fa-fw fa-times" (click)="clearAllDevices()"
                            [ngClass]="{'disabled': blocDevice}">
                        </mat-icon>
                        {{selectedDevices[0].label}}
                    </div>
                </div>
                <div class="tag-device button-container">
                    <button class="add-new-element-button device-button" (click)="clearAllDevices()"
                        *ngIf="selectedDevices.length > 1 && selectedDevices.length !== devices.length">
                        <i class="fa fa-fw fa-times" text-align="left"></i>
                        {{'control.clear-devices' | translate}}
                    </button>
                </div>
            </div>

            <div class="product-wrapper" [formGroup]="controlProductForm">
                <mat-form-field class="product-container">
                    <input type="text" placeholder="{{'control.select-product' | translate}}" aria-label="Number"
                        matInput [matAutocomplete]="auto" formControlName="products">
                    <mat-hint>{{'control.insert-products' | translate}}</mat-hint>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option (click)="addAllProduct()">
                            {{'control.select-all' | translate}}
                            <i class="fa fa-plus" text-align="right"></i>
                        </mat-option>
                        <mat-option *ngFor="let product of filteredProductOptions | async" (click)="addProduct(product.id)"
                            class="product-list">
                            <span>{{product.code + ' - ' + product.name}}</span>
                            <i class="fa fa-plus" text-align="right"></i>
                        </mat-option>

                    </mat-autocomplete>
                </mat-form-field>

                <div class="tag-products" *ngIf="selectedProducts.length !== products.length">
                    <div class="add-new-element-button single-tag" *ngFor="let selectedProduct of selectedProducts">
                        <mat-icon matSuffix class="fa fa-fw fa-times" (click)="removeProduct(selectedProduct.id)"
                            [ngClass]="{'disabled': blocProduct}">
                        </mat-icon>
                        {{selectedProduct.code}}
                    </div>
                </div>
                <div class="tag-products" *ngIf="selectedProducts.length === products.length">
                    <div class="add-new-element-button single-tag">
                        <mat-icon matSuffix class="fa fa-fw fa-times" (click)="clearAllProducts()"
                            [ngClass]="{'disabled': blocProduct}">
                        </mat-icon>
                        {{'control.all-products' | translate}}
                    </div>
                </div>
                <div class="tag-products button-container">
                    <button class="add-new-element-button products-button" (click)="clearAllProducts()"
                        *ngIf="selectedProducts.length > 1 && selectedProducts.length !== products.length">
                        <i class="fa fa-fw fa-times" text-align="left"></i>
                        {{'control.clear-products' | translate}}
                    </button>
                </div>
            </div>
        </div>
        <div class="container">
            <span class="container-title">{{'control.other-info' | translate}}</span>
            <span class="container-title"></span>
        </div>  
        <div class="container padding-bottom">
            <mat-checkbox class="target-time-checkbox" [(ngModel)]="control.idCodeEnabled">
                <span>{{'control.id-code' | translate}}</span>
            </mat-checkbox>
        </div>
    </div>
    <div class="container">
        <span class="container-title-tasks">{{'control.questions' | translate}}</span>
    </div>

    <div class="data-table">
        <div class="datatable-header">
            <div class="datatable-header-cell">
                <div clas="datatable-header-cell-label">
                    <mat-icon class="fa fa-sort"></mat-icon>
                </div>
            </div>
            <div class="datatable-header-cell">
                <div clas="datatable-header-cell-label">
                    {{'control.columns.question' | translate }}
                </div>
            </div>
            <div class="datatable-header-cell">
                <div clas="datatable-header-cell-label">
                    {{'control.columns.enabled' | translate}}
                </div>
            </div>
            <div class="datatable-header-cell">
                <div clas="datatable-header-cell-label">
                    <div class=" datatable-header-cell-template-wrap">
                        <div class="add-new-element-button" (click)="addTask()"
                            [ngClass]="{'disabled': editRow || editRow === 0}">
                            <i class="fa fa-plus"></i>
                            <span> {{ 'control.add-question' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div cdkDropList (cdkDropListDropped)="drop($event)">
            <div class="datatable-body-row" *ngFor="let row of tasks" cdkDrag>
                <div class="datatable-body-cell">
                    <div class="datatable-body-cell-label">
                        <mat-icon class="fa fa-bars"></mat-icon>
                    </div>
                </div>
                <div class="datatable-body-cell">
                    <div class="datatable-body-cell-label">
                        <span class="question-label">{{row.description}}</span>
                    </div>
                </div>
                <div class="datatable-body-cell">
                    <div class="datatable-body-cell-label">
                        <span>
                            <i class="fa fa-check-circle-o" (click)="enablingTask(row.id)"
                                [ngClass]="row.ControlsTasks[0].enabled ? 'enabled' : 'disabled'"></i>
                        </span>
                    </div>
                </div>
                <div class="datatable-body-cell">
                    <div class="datatable-body-cell-label">
                        <button mat-mini-fab [center-mat-menu]="menuTrigger">
                            <mat-icon class="fa fa-ellipsis-h"></mat-icon>
                        </button>
                        <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
                            <mat-menu #menu="matMenu" class="popover-menu">
                                <button mat-menu-item class="popover-menu-item" (click)="editTask(row.id)">
                                    <i class="fa fa-pencil"></i>
                                    {{'control.modify' | translate}}
                                </button>
                                <button mat-menu-item class="popover-menu-item" (click)="cloneTask(row.id)">
                                    <i class="fa fa-clone"></i>
                                    {{'control.duplicate' | translate}}
                                </button>
                                <button mat-menu-item class="popover-menu-item" (click)="deleteTask(row.id)">
                                    <i class="fa fa-trash"></i>
                                    {{'control.delete' | translate}}
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </div>
            <div class="datatable-body-row" *ngIf="tasks.length === 0">
                <div class="datatable-body-cell-empty-row">
                    <div class="empty-row" (click)="addTask()">
                        {{'control.columns.empty-row' | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
