<div class="products-page">
    <div class="content">
        <div class="header">
            <div class="column">
                <h2>{{'products.title' | translate}}</h2>
            </div>
        </div>
        
        <div class="ts-vapor ts-layout layout-container">
            <div class="row">
                <div class="column"></div>
                <div class="column">
                    <div class="button-group end">
                        <ts-button
                            #addArticleButton
                            text="{{'products.addNew' | translate}}"
                            type="primary medium blue"
                            [tsActionTooltip]="newProductTooltip"
                            [tsTooltipPosition]="'bottomRight'"
                            [showTooltip]="showNewProductTooltips"
                            (buttonClicked)="this.onNewProductClick()">
                        </ts-button>    
                    </div>
                </div>
            </div>
            <div class="tableWrp">
                <ts-tree-list #productsList [data]="products" [columns]="productsColumns" key="id"
                                parentKey="groupId" actionsColumnCaption="" groupedActionsTooltipText=""
                                emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                                emptyStateSecondaryText="" emptyStateSecondaryLink="" [enablePaging]="false"
                                [showSearchBar]="false" [hideActionsColumn]="true" [showRowLines]="true"
                                [showBorders]="false" [allowRowDeleting]="false" [allowRowInserting]="false"
                                [allowRowUpdating]="false" [wordWrapEnabled]="true" [height]="productsListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>
                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
                        <app-products-list-cell #actionsProductTooltip [tplKey]="tplKey" [data]="data" [optionsProductIdSelected]="optionsProductIdSelected"
                            (infoProductClicked)="onInfoProductClicked(data)"
                            (editProductClicked)="onEditProductClicked(data)"
                            (optionsProductClicked)="onOptionsProductClicked(data)"
                            (duplicateProductClicked)="onDuplicateProductClicked(data)"
                            (deleteProductClicked)="onDeleteProductClicked(data)"
                        ></app-products-list-cell>
                    </ng-template>
                </ts-tree-list>
            </div>
        </div>
    </div>
</div>

<ng-template #addItem ngx-datatable-header-template>
    <div [ngClass]="{'disabled': editRow || editRow === 0}">
        <ts-button
            text="{{'products.addNew' | translate}}"
            type="primary medium blue"
            [tsActionTooltip]="newProductTooltip"
            [tsTooltipPosition]="'bottomRight'"
            [showTooltip]="showNewProductTooltips"
            (buttonClicked)="this.onNewProductClick()">
        </ts-button>        
    </div>
</ng-template>

<ng-template #newProductTooltip >
    <div class="tooltip-container">
        <div class="btn-wrapper" (mwlClick)="createNewProduct()">
            <ts-button type="primary medium white" icon="box"></ts-button>
            <div style="color: #0077AD; font-size: 16px; font-family: Cairo; font-weight: 500; line-height: 16px; word-wrap: break-word">{{'products.newProduct' | translate}}</div>
        </div>
        <div class="btn-wrapper" (mwlClick)="createNewRowMaterial()">
            <ts-button type="primary medium white" [icon]="['fad', 'box-full']"></ts-button>
            <div style="color: #0077AD; font-size: 16px; font-family: Cairo; font-weight: 500; line-height: 16px; word-wrap: break-word">{{'products.newRowMaterial' | translate}}</div>
        </div>
    </div>
</ng-template>

<!-- Drawer -->
<ng-template #infoProductTemplate>
    <app-product-info-drawer #infoProduct [product]="selectedProduct"></app-product-info-drawer>
</ng-template>
<ng-template #infoProductBottomBarTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.orderInfo.editButton' | translate}}"
                   (closePage)="onEditDrawerClicked(selectedProduct)">
        <ts-button type="tertiary blue medium" text="{{'orders-list.orderInfo.cancelButton' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>

<app-generic-dialog *ngIf="showModal"  [data]="modalData" (primaryButtonClicked)="onDeleteModalClicked()" (cancelButtonClicked)="onCancelModalClicked()"></app-generic-dialog>