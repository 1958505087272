<div class="companies-page">
	<div class="companies-list">
		<div class="company-row">
			<h2 *ngIf="selectedCompany?.name" class="company-title">{{selectedCompany.name}}</h2>
			<span class="satellite-description">{{'companies.satelliteUrl' | translate}}</span>
			<span *ngIf="editRow !== selectedCompany?.id && selectedCompany?.satelliteUrl" class="satellite-url">{{selectedCompany.satelliteUrl}}</span>
			<span *ngIf="editRow !== selectedCompany?.id && !selectedCompany?.satelliteUrl" class="no-satellite-url">{{'companies.no-satelliteUrl' | translate}}</span>
			<mat-form-field class="text-input" *ngIf="editRow === selectedCompany?.id">
				<input matInput type="text" [(ngModel)]="selectedCompany.satelliteUrl">
			</mat-form-field>

			<button *ngIf="editRow !== selectedCompany?.id" mat-mini-fab class="edit-button"
				(click)="editSatelliteUrl(selectedCompany?.id)">
				<mat-icon class="fa fa-pencil"></mat-icon>
			</button>
			<button *ngIf="editRow === selectedCompany?.id" mat-mini-fab (click)="back()">
				<mat-icon class="fa fa-chevron-left"></mat-icon>
			</button>
			<button *ngIf="editRow === selectedCompany?.id" mat-mini-fab class="green-button"
				(click)="saveSatelliteUrl(selectedCompany)">
				<mat-icon class="fa fa-floppy-o"></mat-icon>
			</button>
			<button class="add-new-element-button" (click)="addPlant(selectedCompany)">
				<i class="fa fa-plus" text-align="right"></i>
				{{'companies.add-plant' | translate}}
			</button>
		</div>
		<hr>
		<div class="plant-container" cdkDropListGroup>
			<div class="plant-wrapper">
				<div class="plants-list" *ngFor="let plant of selectedCompany?.Plants">
					<div class="plant-row">
						<h3 *ngIf="!plantRow || plant.id !== plantRow" class="plant-title">{{plant.description}}</h3>
						<mat-form-field class="text-input" *ngIf="plantRow === plant.id">
							<input matInput type="text" [(ngModel)]="plant.description">
						</mat-form-field>
						<button mat-mini-fab (click)="openDialogPlant(plant, selectedCompany?.id)">
							<mat-icon class="fa fa-pencil"></mat-icon>
						</button>
					</div>
					<hr>
					<div class="data-table" cdkDropList #{{plant.id}}
						[cdkDropListData]="filterDevice(selectedCompany?.Devices, plant.id)"
						(cdkDropListDropped)="drop($event, selectedCompany?.id, plant.id)">
						<div>
							<div class="datatable-body-row" *ngFor="let row of filterDevice(selectedCompany?.Devices, plant.id)"
								cdkDrag>
								<div class="datatable-body-cell">
									<div class="datatable-body-cell-label">
										<mat-icon class="fa fa-bars"></mat-icon>
									</div>
								</div>
								<div class="datatable-body-cell">
									<div class="datatable-body-cell-label datatable-body-cell-label-text-left">
										<span class="question-label">{{row.label}}</span>
									</div>
								</div>
								<div class="datatable-body-cell">
									<div class="datatable-body-row datatable-body-row-button-container">
										<div>
											<button mat-mini-fab (click)="openDialogDevice(row, plant)">
												<mat-icon class="fa fa-pencil"></mat-icon>
											</button>
											<!-- <button *ngIf="!row.DeviceModule" mat-mini-fab (click)="openDialogPlant(plant)">
												<mat-icon class="fa fa-pencil"></mat-icon>
											</button> -->
										</div>
										<div>
											<button mat-mini-fab (click)="showQr(row)">
												<mat-icon class="fa fa-qrcode"></mat-icon>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="datatable-body-row" *ngIf="filterDevice(selectedCompany?.Devices, plant.id).length === 0">
							<div class="datatable-body-cell-empty-row">
								<div class="empty-row">
									{{'companies.no-device' | translate}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>