<div class="orders-page">
    <mat-form-field class="machine-select" *ngIf="isAdmin">
        <mat-select placeholder="{{'devices.columns.company' | translate}}" class="machine-select"
            [(ngModel)]="selectedCompany" (ngModelChange)="companyChanged()">
            <mat-option *ngFor="let company of companies" [value]="company">{{company.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <ngx-datatable [rows]="tmpOrdersList" [columns]="columns" [columnMode]="'force'" [headerHeight]="50"
        [rowHeight]="80" [scrollbarV]="true" class="data-table">
    </ngx-datatable>
</div>

<ng-template #code let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <span *ngIf="editRow !== rowIndex" class="order-code">{{row.code}}</span>
        <mat-form-field *ngIf="editRow === rowIndex">
            <input matInput type="text" placeholder="{{'orders.insert-code' | translate}}" [(ngModel)]="row.code" (ngModelChange)="setModified()">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #product let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <span *ngIf="editRow !== rowIndex" class="tag-name">{{row.productCode}}</span>
        <mat-form-field *ngIf="editRow === rowIndex">
            <input type="text" placeholder="{{'products.title' | translate}}" matInput [matAutocomplete]="auto"
                [formControl]="controlForm">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let item of filteredOptions | async" [value]="item" class="product-list">
                    <span>{{item.code + ' - ' + item.name}}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #target let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <span *ngIf="editRow !== rowIndex" class="prod-label">{{row.target}}
            <span>{{'units.short_pieces' | translate}}</span></span>
        <mat-form-field class="target" *ngIf="editRow === rowIndex">
            <input matInput type="number" [(ngModel)]="row.target" (ngModelChange)="setModified()">
            <label>{{'units.short_pieces' | translate}}</label>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #targetSpeed let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <span *ngIf="editRow !== rowIndex" class="prod-label">{{row.targetSpeed}}
            <span>{{'units.speedPieces' | translate}}</span></span>
        <mat-form-field class="targetSpeed" *ngIf="editRow === rowIndex">
            <input matInput type="number" [(ngModel)]="row.targetSpeed" (ngModelChange)="setModified()">
            <label>{{'units.speedPieces' | translate}}</label>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #deliveryDate let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <span *ngIf="editRow !== rowIndex" class="tag-name">{{row.device.label}}</span>
        <mat-form-field>
            <input matInput [matDatepicker]="deliveryDate" placeholder="{{'orders.insert-code' | translate}}"
                [value]="row.deliveryDate">
            <mat-datepicker-toggle matSuffix [for]="deliveryDate"></mat-datepicker-toggle>
            <mat-datepicker #deliveryDate></mat-datepicker>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #done let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="slide-toggle-container">
            <span>
                <i class="fa fa-check-circle-o" *ngIf="row.done"></i>
                <!-- <i class="fa fa-times-circle-o" *ngIf="!row.enabled"></i> -->
            </span>
    </div>
</ng-template> 

<ng-template #actions let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="buttons-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <button mat-mini-fab (click)="back()" *ngIf="editRow === rowIndex">
            <mat-icon class="fa fa-chevron-left"></mat-icon>
        </button>
        <button mat-mini-fab class="red-button" (click)="delete(row, rowIndex)" *ngIf="editRow !== rowIndex">
            <mat-icon class="fa fa-trash-o"></mat-icon>
        </button>
        <button mat-mini-fab class="green-button" (click)="save(row, rowIndex)" *ngIf="editRow === rowIndex"
            [disabled]="!modified || row.code.length === 0 || row.target === 0 || !row.target || !controlForm.value.code">
            <mat-icon class="fa fa-floppy-o"></mat-icon>
        </button>
        <!-- <button mat-mini-fab *ngIf="editRow !== rowIndex" (click)="edit(rowIndex)">
            <mat-icon class="fa fa-pencil"></mat-icon>
        </button> -->
    </div>
</ng-template>

<ng-template #addItem ngx-datatable-header-template>
    <div class="add-new-element-button" (click)="add()" [ngClass]="{'disabled': editRow || editRow === 0}">
        <i class="fa fa-plus"></i>
        <span>{{'tags.addNew' | translate}}</span>
    </div>
</ng-template>