<div class="shift-page" (contextmenu)="onRightClick()">

    <mat-form-field class="machine-select">
        <mat-label>{{'devices.single' | translate}}</mat-label>
        <mat-select placeholder="{{'devices.single' | translate}}" [(ngModel)]="selectedDevice"
            (ngModelChange)="deviceChanged()">
            <mat-option *ngFor="let device of devices" [value]="device">{{device.label}}
                ({{ device.Company ? device.Company.name : '?' }})</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="mat-dialog-actions-shift">
        <div class="calendar-left-wrapper">
            <button class="calendar-btn" (click)="cloneWeek()"> {{'work-shifts.copy-week' | translate}}</button>
            <button class="calendar-btn" mwlCalendarToday [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate)">{{'work-shifts.today' | translate}}</button>
        </div>
        <i class="fa fa-angle-left week-selector" mwlCalendarPreviousView [view]="'week'" [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"></i>
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn }}</h3>
        <i class="fa fa-angle-right week-selector" mwlCalendarNextView [view]="'week'" [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"></i>
        <div class="calendar-right-wrapper">
            <mat-slide-toggle class="slide-toggle" [checked]="forceStop" (change)="onForceStopChange($event)"
                matTooltip="{{'work-shifts.force-stop-tooltip' | translate}}" matTooltipPosition="above"
                matTooltipClass="tooltip wide">
                {{ 'work-shifts.force-stop' | translate }}
            </mat-slide-toggle>
            <i class="fa fa-question-circle" matTooltip="{{'work-shifts.force-stop-tooltip' | translate}}"
                matTooltipPosition="above" matTooltipClass="tooltip wide"></i>
            <mat-slide-toggle class="slide-toggle" [checked]="changeStop" (change)="onChangeStopChange($event)"
                matTooltip="{{'work-shifts.change-stop-tooltip' | translate}}" matTooltipPosition="above"
                matTooltipClass="tooltip wide">
                {{ 'work-shifts.change-stop' | translate }}
            </mat-slide-toggle>
            <i class="fa fa-question-circle" matTooltip="{{'work-shifts.change-stop-tooltip' | translate}}"
                matTooltipPosition="above" matTooltipClass="tooltip wide"></i>
            <mat-slide-toggle class="slide-toggle" [checked]="startSession" (change)="onStartSessionChange($event)"
                matTooltip="{{'work-shifts.start-session-tooltip' | translate}}" matTooltipPosition="above" matTooltipClass="tooltip wide">
                {{ 'work-shifts.start-session' | translate }}
            </mat-slide-toggle>
            <i class="fa fa-question-circle" matTooltip="{{'work-shifts.start-session-tooltip' | translate}}"
                matTooltipPosition="above" matTooltipClass="tooltip wide"></i>
        </div>
    </div>

    <ng-template #weekViewHourSegmentTemplate let-segment="segment" let-locale="locale"
        let-segmentHeight="segmentHeight" let-isTimeLabel="isTimeLabel">

        <div #segmentElement class="cal-hour-segment" [style.height.px]="segmentHeight"
            [class.cal-hour-start]="segment.isStart" [class.cal-after-hour-start]="!segment.isStart"
            [ngClass]="segment.cssClass" (mousedown)="startDragToCreate(segment, $event, segmentElement)">

            <div class="cal-time" *ngIf="isTimeLabel"> {{ segment.date | calendarDate:'weekViewHour':locale }} </div>
        </div>
    </ng-template>

    <mwl-calendar-week-view [hourSegmentHeight]="hourSegmentHeight" [hourSegments]="hourSegments" [viewDate]="viewDate" [events]="events"
        [locale]="locale" [weekStartsOn]="weekStartsOn" [refresh]="refresh_changed"
        [hourSegmentTemplate]="weekViewHourSegmentTemplate" (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-week-view>
</div>
