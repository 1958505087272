<div class="errors-page">
    <mat-form-field class="machine-select">
        <mat-label>{{'devices.single' | translate}}</mat-label>
        <mat-select [(ngModel)]="selectedDevice" (ngModelChange)="deviceChanged()">
            <mat-option *ngFor="let device of devices" [value]="device">{{device.label}}
                ({{ device.Company ? device.Company.name : '?' }})</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-tab-group (selectedTabChange)="tabChanged($event)">
        <mat-tab label="{{'stopReasons.planned' | translate}}"></mat-tab>
        <mat-tab label="{{'stopReasons.unplanned' | translate}}"></mat-tab>
        <mat-tab *ngIf="isDelaysManager" label="{{'stopReasons.delays' | translate}}"></mat-tab>
    </mat-tab-group>

    <!-- <div class="filters" *ngIf="showPlanned">
        <div class="half">
            <mat-form-field>
                <input matInput type="text" [(ngModel)]="plannedSearch" (ngModelChange)="filterPlanned()" placeholder="{{'stopReasons.filterText' | translate}}">
            </mat-form-field>
        </div>

        <div class="half">
            <mat-select placeholder="{{'stopReasons.filterCategory' | translate}}" [(ngModel)]="plannedCategoryFilter" (ngModelChange)="filterPlanned()">
                <mat-option *ngFor="let cat of errorCategories" [value]="cat">{{cat.name}}</mat-option>
            </mat-select>
        </div>

        <div class="small">
            <button mat-mini-fab (click)="filterPlannedReset()">
                <i class="fa fa-times"></i>
            </button>
        </div>
    </div> -->

    <ngx-datatable #plannedTable *ngIf="showPlanned && !showDelays" [rows]="tmpPlanned" [columns]="columns"
        [columnMode]="'force'" [headerHeight]="80" [rowHeight]="60" [scrollbarV]="true" class="error-table data-table">
    </ngx-datatable>

    <!-- <div class="filters" *ngIf="!showPlanned">
        <div class="half">
            <mat-form-field>
                <input matInput type="text" [(ngModel)]="unplannedSearch" (ngModelChange)="filterUnplanned()" placeholder="{{'stopReasons.filterText' | translate}}">
            </mat-form-field>
        </div>

        <div class="half">
            <mat-select placeholder="{{'stopReasons.filterCategory' | translate}}" [(ngModel)]="unplannedCategoryFilter" (ngModelChange)="filterUnplanned()">
                <mat-option *ngFor="let cat of errorCategories" [value]="cat">{{cat.name}}</mat-option>
            </mat-select>
        </div>

        <div class="small">
            <button mat-mini-fab (click)="filterUnplannedReset()">
                <i class="fa fa-times"></i>
            </button>
        </div>
    </div> -->

    <ngx-datatable #unplannedTable *ngIf="!showPlanned && !showDelays" [rows]="tmpUnplanned" [columns]="unplannedColumns"
        [columnMode]="'force'" [headerHeight]="80" [rowHeight]="60" [scrollbarV]="true" class="error-table data-table">
    </ngx-datatable>

    <ngx-datatable #delaysTable *ngIf="showDelays" [rows]="tmpDelays" [columns]="delayColumns" [columnMode]="'force'"
        [headerHeight]="50" [rowHeight]="80" [scrollbarV]="true" class="error-table data-table">
    </ngx-datatable>
</div>

<ng-template #addItem ngx-datatable-header-template>
    <div *ngIf="showPlanned && !showDelays" class="add-new-element-button" (click)="addError()"
        [ngClass]="{'disabled': plannedEditRow || plannedEditRow === 0}">
        <i class="fa fa-plus"></i>
        <span>{{'stopReasons.addNew' | translate}}</span>
    </div>

    <div *ngIf="!showPlanned && !showDelays" class="add-new-element-button" (click)="addError()"
        [ngClass]="{'disabled': unPlannedEditRow || unPlannedEditRow === 0}">
        <i class="fa fa-plus"></i>
        <span>{{'stopReasons.addNew' | translate}}</span>
    </div>

    <div *ngIf="showDelays" class="add-new-element-button" (click)="addDelay()"
        [ngClass]="{'disabled': delaysEditRow || delaysEditRow === 0}">
        <i class="fa fa-plus"></i>
        <span>{{'stopReasons.addNew' | translate}}</span>
    </div>
</ng-template>

<!-- Column part for stops -->

<ng-template #code let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <span *ngIf="!isEditRow(rowIndex)" class="tag-name">{{row.code}}</span>
        <mat-form-field *ngIf="isEditRow(rowIndex)">
            <input matInput type="text" [(ngModel)]="row.code" (ngModelChange)="setModified(rowIndex)">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #text let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <span *ngIf="!isEditRow(rowIndex)" class="tag-name">{{row.text}}</span>
        <mat-form-field *ngIf="isEditRow(rowIndex)">
            <input matInput type="text" [(ngModel)]="row.text" (ngModelChange)="setModified(rowIndex)">
        </mat-form-field>
    </div>
</ng-template>

<!-- Column part for delays -->

<ng-template #description let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <span *ngIf="!isEditRow(rowIndex)" class="tag-name">{{row.description}}</span>
        <mat-form-field *ngIf="isEditRow(rowIndex)">
            <input matInput type="text" [(ngModel)]="row.description" (ngModelChange)="setModified(rowIndex)">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #category let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <div *ngIf="!isEditRow(rowIndex)">
            <div *ngFor="let category of errorCategories">
                <span class="tag-name" *ngIf="category.id === row.categoryId">{{category.name}}</span>
            </div>
        </div>
        <mat-select placeholder="{{'stopReasons.columns.category' | translate}}" *ngIf="isEditRow(rowIndex)"
            [(ngModel)]="row.categoryId" floatPlaceholder="never" (ngModelChange)="setModified(rowIndex)">
            <mat-option *ngFor="let category of errorCategories | filterCategory: (showPlanned ? 1 : 0)"
                [value]="category.id">{{category.name}}</mat-option>
        </mat-select>
    </div>
</ng-template>

<ng-template #priority let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="priority-container">
        <span (click)="updatePriorityError(row.id !== selectedDevice.priorityErrorId ? row.id : 0)">
            <i class="fa fa-dot-circle-o" *ngIf="row.id === selectedDevice.priorityErrorId"></i>
            <i class="fa fa-circle-o" *ngIf="row.id !== selectedDevice.priorityErrorId"></i>
        </span>
    </div>
</ng-template>

<ng-template #tag let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <div *ngIf="!isEditRow(rowIndex)">
            <div *ngFor="let tag of errorTags">
                <span class="tag-name" *ngIf="tag.id && tag.id === row.ErrorsTags[0].id">{{tag.name}}</span>
            </div>
        </div>
        <mat-select placeholder="Group" *ngIf="isEditRow(rowIndex)" [(ngModel)]="row.ErrorsTags[0].id"
            floatPlaceholder="never" (ngModelChange)="setModified(rowIndex)">
            <mat-option *ngFor="let tag of errorTags" [value]="tag.id">{{tag.name}}</mat-option>
        </mat-select>
    </div>
</ng-template>

<ng-template #target let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <span *ngIf="!isEditRow(rowIndex)" class="tag-name">{{row.target}}</span>
        <mat-form-field *ngIf="isEditRow(rowIndex)">
            <input matInput type="text" [(ngModel)]="row.target" (ngModelChange)="setModified(rowIndex)">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #devicesTemplate let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="device-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <span *ngIf="!isEditRow(rowIndex) && row.Devices.length < 2">{{row.Devices.length}}
            {{'stopReasons.devices.singular' | translate}}</span>
        <span *ngIf="!isEditRow(rowIndex) && row.Devices.length > 1">{{row.Devices.length}}
            {{'stopReasons.columns.devices' | translate}}</span>
        <button mat-button (click)="manageDevices(row, rowIndex)" class="btn-device"
            *ngIf="isEditRow(rowIndex) && row.Devices.length < 2">
            {{row.Devices.length}} {{'stopReasons.devices.singular' | translate}}
            <!-- {{row.Devices.length}} {{'devices.title' | translate}} -->
        </button>
        <button mat-button (click)="manageDevices(row, rowIndex)" class="btn-device"
            *ngIf="isEditRow(rowIndex) && row.Devices.length > 1">
            {{row.Devices.length}} {{'stopReasons.columns.devices' | translate}}
            <!-- {{row.Devices.length}} {{'devices.title' | translate}} -->
        </button>
    </div>
</ng-template>

<ng-template #enabled let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="slide-toggle-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <div *ngIf="!isEditRow(rowIndex)">
            <span>
                <i class="fa fa-check-circle-o" *ngIf="row.enabled"></i>
                <!-- <i class="fa fa-times-circle-o" *ngIf="!row.enabled"></i> -->
            </span>
        </div>
        <mat-slide-toggle [(ngModel)]="row.enabled" (ngModelChange)="setModified(rowIndex)" *ngIf="isEditRow(rowIndex)">
        </mat-slide-toggle>
    </div>
</ng-template>

<ng-template #actions let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="buttons-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <button mat-mini-fab (click)="back(rowIndex)" *ngIf="isEditRow(rowIndex)">
            <mat-icon class="fa fa-chevron-left"></mat-icon>
        </button>
        <button mat-mini-fab class="red-button" (click)="deleteError(row, rowIndex)" *ngIf="isEditRow(rowIndex)">
            <mat-icon class="fa fa-trash-o"></mat-icon>
        </button>
        <button mat-mini-fab class="green-button" (click)="saveError(row, rowIndex)" *ngIf="isEditRow(rowIndex)"
            [disabled]="!isModified() || row.categoryId === -1 || !row.text.length">
            <mat-icon class="fa fa-floppy-o"></mat-icon>
        </button>
        <button mat-mini-fab *ngIf="!isEditRow(rowIndex)" (click)="edit(rowIndex)">
            <mat-icon class="fa fa-pencil"></mat-icon>
        </button>
    </div>
</ng-template>

<ng-template #actionsDelay let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="buttons-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <button mat-mini-fab (click)="backDelay(rowIndex)" *ngIf="isEditRow(rowIndex)">
            <mat-icon class="fa fa-chevron-left"></mat-icon>
        </button>
        <button mat-mini-fab class="red-button" (click)="deleteDelay(row, rowIndex)" *ngIf="isEditRow(rowIndex)">
            <mat-icon class="fa fa-trash-o"></mat-icon>
        </button>
        <button mat-mini-fab class="green-button" (click)="saveDelay(row, rowIndex)" *ngIf="isEditRow(rowIndex)"
            [disabled]="!isModified() || !row.description.length">
            <mat-icon class="fa fa-floppy-o"></mat-icon>
        </button>
        <button mat-mini-fab *ngIf="!isEditRow(rowIndex)" (click)="edit(rowIndex)">
            <mat-icon class="fa fa-pencil"></mat-icon>
        </button>
    </div>
</ng-template>