<div class="order-editor-new-order">
    <div *ngIf="!editMode">
        <div class="steps">
            <ng-container *ngFor="let singleStep of stepList; index as i">
                <div [classList]="step == i + 1 ? 'selected point' : 'point'">
                    <span class='centered'>
                        {{singleStep.currentStep}}
                    </span>
                    <span class="description">
                        {{singleStep.description}}
                    </span>
                </div>
                <div *ngIf="stepList.length - 1 !== i" class="divider"></div>
            </ng-container>
        </div>
    </div>
    <div [classList]="step == 2 ? 'display_none' : ''">
        <form [formGroup]="formOrderData" [autocomplete]="false">
            <div class="section">
                <div class="title">{{'orders.createEdit.statusOrder' | translate}}</div>
                <div class="row">
                    <ts-select 
                        class="select fw400" 
                        [data]="selectStatusOrderData" 
                        [clearable]="true"
                        placeholderText="{{'orders.createEdit.status' | translate}}"
                        formControlName="status"
                        [value]="formOrderData.get('status').value"
                        [enabled]="true"
                        [showErrors]="true"
                        primaryText="{{'orders.createEdit.status' | translate}} *"
                        width="204px"
                        [validationDict]="customValidationDict | translateJSON | async">
                    </ts-select>
                </div>
            </div>

            <div class="section">
                <div class="title">{{'orders.createEdit.orderData' | translate}}</div>
                <div class="row">
                    <ts-text-box 
                        class="fw400"
                        primaryText="{{'orders-list.columns.code' | translate}} *"
                        placeholderText="{{'orders-list.columns.code' | translate}}" 
                        formControlName="code"
                        [showErrors]="true"
                        [enabled]="!editMode"
                        width="204px" 
                        [validationDict]="customValidationDict | translateJSON | async">
                    </ts-text-box>
                    <ts-datepicker 
                        class="fw400"
                        mode="date"
                        primaryText="{{'orders-list.columns.date' | translate}} *"
                        placeholderText="{{'orders-list.columns.date' | translate}}"
                        [showErrors]="f.date.invalid && f.date.touched" 
                        width="204px"
                        formControlName="date"
                        [validationDict]="customValidationDict | translateJSON | async">
                    </ts-datepicker>
                </div>
            </div>

            <div class="section">
                <div class="title">{{'orders.createEdit.association' | translate}}</div>
                <div class="row">
                    <ts-autocomplete 
                        class="select max"
                        #product
                        primaryText="{{'orders.createEdit.product' | translate}} *"
                        placeholder="{{'orders.createEdit.product' | translate}}" 
                        prefix="" 
                        suffix=""
                        formControlName="product"
                        [enabled]="!editMode" 
                        width="204px" 
                        [showErrors]="true"
                        [validationDict]="customValidationDict | translateJSON | async">
                    </ts-autocomplete>
                    <div class="input-number-wrapper">
                        <ts-text-box
                            class="fw400 number"
                            primaryText="{{'orders.createEdit.target' | translate}} *"
                            placeholderText="{{'orders.createEdit.target' | translate}}"
                            formControlName="target"
                            width="204px" 
                            [showErrors]="true"
                            [validationDict]="customValidationDict | translateJSON: {min: 0} | async">
                        </ts-text-box>
                        <span class="suffix">{{selectedProductProductionUnit?.unit ? selectedProductProductionUnit?.unit.length > 4 ? selectedProductProductionUnit?.unit.substr(0, 3) + "..." : selectedProductProductionUnit?.unit : ""}}</span>
                    </div>
                </div>
            </div>
            
        </form>

        <ts-accordion-panel #accordion
            title="{{'products.newEditProduct.additionalInfo' | translate}}" 
            [opened]="additionalInfoOpen" 
            (toggle)="toggleAdditionalInfo()">
            <div class="accordionContent">
                <form [formGroup]="additionalInfoForm" *ngIf="additionalInfoForm">
                    <div formArrayName="keyValueArray">
                        <ng-container *ngFor="let info of keyValueArray?.controls; let i = index " [formGroupName]="i">
                            <div class="input-row accordion">
                                <ts-text-box primaryText="{{'products.newEditProduct.description' | translate}}*"
                                placeholderText="{{'products.newEditProduct.description' | translate}}" formControlName="key"
                                [validationDict]="customValidationDict | translateJSON | async"
                                [showErrors]="true" width="257px"></ts-text-box>

                                <ts-text-box primaryText="{{'products.newEditProduct.value' | translate}}*"
                                placeholderText="{{'products.newEditProduct.value' | translate}}" formControlName="value"
                                [validationDict]="customValidationDict | translateJSON | async"
                                [showErrors]="true" width="257px"></ts-text-box>

                                <ts-button
                                    [type]="'tertiary medium alert'"
                                    icon="trash-alt"
                                    iconSize="1x" 
                                    (buttonClicked)="onHandleRemoveInfoButtonClicked(i)"
                                ></ts-button>                              
                            </div>
                        </ng-container>
                    </div>
                </form>                                    
                <div class="addInfoRow">
                    <ts-button
                    [type]="'tertiary medium blue'"
                    text="{{'products.newEditProduct.addInfo' | translate}}"
                    icon="plus-circle"
                    iconSize="1x" 
                    (buttonClicked)="onHandleNewInfoButtonClicked()"
                    ></ts-button>                                
                </div>
            </div>
        </ts-accordion-panel>
        
    </div>

    <div [classList]="step == 1 ? 'display_none' : ''">
        <div class="section">
            <div class="wcTitle">{{'orders.createEdit.WorkCycleData' | translate}}</div>
            <div class="wcSummary">
                <div>
                    <span class="wcLabel">{{formOrderData.get('product')?.value?.code}}</span>
                    <span class="wcValue">{{formOrderData.get('product')?.value?.name}}</span>
                </div>
            </div>
        </div>
        
        <div class="section">
            <div class="col">
                <span class="wcTitleBig">{{'orders.createEdit.PhasesOfTheWorkCycle' | translate}}</span>
                <span class="wcValue">{{'orders.createEdit.phaseCreationHint'  | translate}}</span>
            </div>
                
        </div>

        <form *ngIf="formCycleData" class="cycleForm" [formGroup]="formCycleData" [autocomplete]="false">
            <div formArrayName="phasesArray">
                <ng-container *ngFor="let singlePhase of phasesArray?.controls; let i = index" [formGroupName]="i">
                    <div class="cardWrp">
                        <div class="card">
                            <div class="header">
                                <span class="wcCardLabel">{{'orders.createEdit.phase' | translate}} {{this.phasesList[i]?.sequence ? (this.phasesList[i]?.sequence + ':') : ''}} {{this.phasesList[i]?.workName}}</span>
                            </div>
                            <div class="content">
                                <div class="row">
                                    <div class="section row">
                                        <ts-number-box
                                            class="fw400 no-spinner disabled"
                                            primaryText="{{'orders.createEdit.sequence' | translate}}"
                                            placeholderText="{{'orders.createEdit.sequence' | translate}}"
                                            [enabled]="false"
                                            primaryIcon=""
                                            formControlName="sequence"
                                            [min]="0"
                                            [showErrors]="false"
                                            width="204px">
                                        </ts-number-box>

                                        <ts-autocomplete 
                                            class="select max"
                                            #worksSelect
                                            primaryText="{{'orders.createEdit.work' | translate}} *"
                                            placeholder="{{'orders.createEdit.work' | translate}}" 
                                            prefix="" 
                                            suffix=""
                                            formControlName="workId"
                                            width="204px" 
                                            [showErrors]="true"
                                            (valueChange)="onWorkIdChange(i, $event)"
                                            [validationDict]="customValidationDict | translateJSON | async">
                                        </ts-autocomplete>                                
                                        
                                        <ts-select 
                                            class="select fw400" 
                                            [data]="selectResourceTypeData" 
                                            [clearable]="true"
                                            placeholderText="{{'orders.createEdit.resourceType' | translate}}"
                                            formControlName="resourceType"
                                            [enabled]="true"
                                            [showErrors]="true"
                                            primaryText="{{'orders.createEdit.resourceType' | translate}} *"
                                            width="204px"
                                            height="40px"
                                            (valueChange)="onResourceTypeChange($event)"
                                            [validationDict]="customValidationDict | translateJSON | async">
                                        </ts-select>
                                        <div class="ts-input-container resourceIds" width="300px">
                                            <div class="ts-input-container__header">
                                                <div class="ts-input-container__header__primary">
                                                    <span class="ts-input-container__header__primary__text">
                                                        {{resourceTypeLabel}} *
                                                    </span>
                                                </div>
                                                <span class="ts-input-container__header__optional"></span>
                                            </div>
                                            <div class="ts-input-container__data">
                                                <ts-simple-select 
                                                    #resourcesSelect
                                                    class="select max" 
                                                    [data]="selectResourceData" 
                                                    [multiSelect]="true" 
                                                    [clearable]="true"
                                                    [showErrors]="true"
                                                    formControlName="resourceIds"
                                                    placeholderText="{{resourceTypeLabel}}"
                                                    width="204px">
                                                </ts-simple-select>
                                            </div>
                                        </div>
                                    </div>
                            
                                    <div class="section row">
                                        <div class="input-number-wrapper">
                                            <ts-text-box
                                                class="fw400 number"
                                                primaryText="{{'orders.createEdit._target' | translate}} *"
                                                placeholderText="{{'orders.createEdit._target' | translate}}"
                                                [value]=""
                                                width="204px" 
                                                formControlName="target"
                                                [showErrors]="true"
                                                [validationDict]="customValidationDict | translateJSON: {min: 0} | async">
                                            </ts-text-box>
                                            <span class="suffix">{{selectedProductProductionUnit?.unit ? selectedProductProductionUnit?.unit.length > 4 ? selectedProductProductionUnit?.unit.substr(0, 3) + "..." : selectedProductProductionUnit?.unit : ""}}</span>
                                        </div>

                                        <ts-datepicker class="wcDateTime" mode="datetime" primaryText="{{'orders.createEdit.startSateAndTime' | translate}}"
                                        placeholderText="{{'orders.createEdit.startSateAndTime' | translate}}"
                                        [showErrors]="f.date.invalid && f.date.touched" width="204px" 
                                        formControlName="startDateTime" [validationDict]="customValidationDict | translateJSON | async"></ts-datepicker>

                                        <ts-datepicker class="wcDateTime" mode="datetime" primaryText="{{'orders.createEdit.endSateAndTime' | translate}}"
                                        placeholderText="{{'orders.createEdit.endSateAndTime' | translate}}"
                                        [showErrors]="f.date.invalid && f.date.touched" width="204px" [minDate]="singlePhase.get('startDateTime').value"
                                        formControlName="endDateTime" [validationDict]="customValidationDict | translateJSON | async"></ts-datepicker>
                                    </div>
                                    <div class="section row">
                                        <vapor-angular-text-area class=" text-area" width="1163px"
                                        primaryText="{{'orders.createEdit.Notes' | translate}}"
                                        placeholderText="{{'orders.createEdit.Notes' | translate}}"
                                        formControlName="notes" [minLength]="1" [maxLength]="100"
                                        [value]="singlePhase.get('notes').value"
                                        [validationDict]="customValidationDict | translateJSON | async"
                                        [showErrors]="true"></vapor-angular-text-area>                
                                    </div>
                                    <div *ngIf="plantHasComponentsListModule" class="section row sb100">
                                        <div class="wcList">
                                            <span>
                                                {{'orders.createEdit.componentsList' | translate}}
                                            </span>
                                            <div *ngIf="this.phasesList?.[i].componentsList?.length > 0" class="counter">{{this.phasesList?.[i].componentsList?.length}}</div>
                                        </div>               
                                        <span (click)="onOpenComponentsList(i)" class="link">+ {{this.phasesList?.[i].componentsList?.length > 0 ? ('orders.createEdit.editComponentList' | translate) : ('orders.createEdit.enterComponentList' | translate)}}</span>                            
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </form> 
    </div>
</div>