import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpXsrfTokenExtractor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class CustomXsrfInterceptor implements HttpInterceptor {
    constructor(
        private tokenExtractor: HttpXsrfTokenExtractor,
        private _envSettings: EnvConfigurationService,
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this._envSettings.settings) {
            const token = this.tokenExtractor.getToken() as string | null;
            //console.log('Extracted XSRF token:', token);
            //console.log('Request URL:', request.url);
    
            // Log all cookies
            const cookies = document.cookie;
            //console.log('Cookies:', cookies);
    
            // Check if the token is null and log a warning
            if (token === null) {
                //console.warn('XSRF token is null. Ensure the token is set in the cookies.');
            }

            // Set the header only for requests to the API and not for external domains
            // (use EnvConfigurationService instead of ConfigService to avoid a circular dependency)
            if (token !== null && request.url.startsWith(this._envSettings.settings.api.baseUrl)) {
                request = request.clone({
                    headers: request.headers.set('X-XSRF-TOKEN', token)
                });
            }
        }

        return next.handle(request);
    }
}
