import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { VariationGroupsInstance, VariationsInstance } from '../models/variation.model';


@Injectable()
export class VariationsService {
    variations: VariationsInstance[];
    variationsGroups: VariationGroupsInstance[];


    constructor(private _http: HttpService, private _config: ConfigService) { }

    async getVariationsGroups(companyId?: number) {
        let url = `${this._config.API.urlV2}/variationGroups/company/${companyId}`;

        const response = await this._http.get<VariationGroupsInstance[]>(url);

        this.variationsGroups = response.body;
        return this.variationsGroups;
    }
}
