<div class="users-page">
    <ngx-datatable [rows]="tmpUsers" [columns]="columns" [columnMode]="'force'" [headerHeight]="50"
        [rowHeight]="60" [scrollbarV]="true" class="data-table">
    </ngx-datatable>
</div>


<ng-template #addItem ngx-datatable-header-template>
    <div class="add-new-element-button" (click)="add()" [ngClass]="{'disabled': editRow || editRow === 0}">
        <i class="fa fa-plus"></i>
        <span> {{ 'users.addNew' | translate}}</span>
    </div>
</ng-template>

<ng-template #roleHelp ngx-datatable-header-template>
    <div class="role-header" matTooltip="{{'users.columns.role-tooltip' | translate}}"
            matTooltipPosition="above" matTooltipClass="tooltip wide">
        <span> {{ 'users.columns.role' | translate}}</span>
        <i class="fa fa-question-circle"></i>
    </div>
</ng-template>

<ng-template #email let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <span *ngIf="!isEditRow(rowIndex)" class="user-text">{{row.email}}</span>
        <mat-form-field *ngIf="isEditRow(rowIndex)">
            <input matInput type="text" [(ngModel)]="row.email" (ngModelChange)="setModified()">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #firstName let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <span *ngIf="!isEditRow(rowIndex)" class="user-text">{{row.firstName}}</span>
        <mat-form-field *ngIf="isEditRow(rowIndex)">
            <input matInput type="text" [(ngModel)]="row.firstName" (ngModelChange)="setModified()">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #lastName let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <span *ngIf="!isEditRow(rowIndex)" class="user-text">{{row.lastName}}</span>
        <mat-form-field *ngIf="isEditRow(rowIndex)">
            <input matInput type="text" [(ngModel)]="row.lastName" (ngModelChange)="setModified()">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #role let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <span *ngIf="!isEditRow(rowIndex) && row.role===3" class="user-text"
            [ngClass]="{'current':current===row.role}">Manager</span>
        <span *ngIf="!isEditRow(rowIndex) && row.role===1" class="user-text"
            [ngClass]="{'current':current===row.role}">Supervisor</span>
        <mat-form-field *ngIf="isEditRow(rowIndex)">
            <mat-select class="machine-select" [(ngModel)]="row.role" floatPlaceholder="never" (ngModelChange)="setModified()">
                <mat-option [value]="3">Manager</mat-option>
                <mat-option [value]="1">Supervisor</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #plant let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <span *ngIf="!isEditRow(rowIndex) && (!row.Plants || row.Plants.length==0)" class="user-text current">{{'companies.all-plants' | translate}}</span>
    <span *ngIf="!isEditRow(rowIndex) && row.Plants && row.Plants.length>0" class="user-text current">{{row.Plants[0].description}}</span>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <mat-form-field *ngIf="isEditRow(rowIndex)">
            <mat-select class="machine-select" [(ngModel)]="row.plant.id" floatPlaceholder="never" (ngModelChange)="setModified()">
                <mat-option [value]="-1">{{'companies.all-plants' | translate}}</mat-option>
                <mat-option *ngFor="let option of plants" [value]="option.id">{{ option.description }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-template>

<!-- <ng-template #enabled let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="slide-toggle-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <div *ngIf="!isEditRow(rowIndex)">
            <span>
                <i class="fa fa-check-circle-o" *ngIf="row.enabled"></i>
            </span>
        </div>
        <mat-slide-toggle
            [(ngModel)]="row.enabled"
            (ngModelChange)="setModified(rowIndex)"
            *ngIf="isEditRow(rowIndex)">
        </mat-slide-toggle>
    </div>
</ng-template> -->

<ng-template #actions let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="buttons-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <button mat-mini-fab (click)="back(rowIndex)" *ngIf="isEditRow(rowIndex)">
            <mat-icon class="fa fa-chevron-left"></mat-icon>
        </button>
        <button mat-mini-fab class="red-button" (click)="delete(row, rowIndex)" *ngIf="isEditRow(rowIndex)">
            <mat-icon class="fa fa-trash-o"></mat-icon>
        </button>
        <button mat-mini-fab class="green-button" (click)="save(row, rowIndex)" *ngIf="isEditRow(rowIndex)"
            [disabled]="!modified || row.email.length === 0 || !row.role">
            <mat-icon class="fa fa-floppy-o"></mat-icon>
        </button>
        <button mat-mini-fab *ngIf="editRow !== rowIndex" (click)="edit(rowIndex)">
            <mat-icon class="fa fa-pencil"></mat-icon>
        </button>
    </div>
</ng-template>