<div class="new-device-dialog">

    <h1 mat-dialog-title>
        New device -
        {{data.label ? data.label : data.unipiId}}
        {{data.companyId ? '(' + data.company.name + ')' : ''}}
    </h1>
    <div mat-dialog-content>
        <form>
            <!-- Device -->
            <mat-form-field>
                <mat-label>Unipi</mat-label>
                <mat-select name="unipiId" [(ngModel)]="data.unipiId" [disabled]="true">
                    <mat-option *ngFor="let unipi of unipis" [value]="unipi.id">{{unipi.id}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <input matInput name="label" [(ngModel)]="data.label" placeholder="Label" />
            </mat-form-field>
            <br>
            <hr>
            <!-- Company -->
            <mat-radio-group name="companyType" [(ngModel)]="data.__companyType" (ngModelChange)="data.company = {};">
                <mat-radio-button value="existing">Existing company</mat-radio-button>
                <mat-radio-button value="new">New company</mat-radio-button>
            </mat-radio-group>
            <br>
            <mat-form-field *ngIf="data.__companyType === 'existing'">
                <mat-label>Company ID</mat-label>
                <mat-select name="company" [(ngModel)]="data.company" placeholder="Company ID">
                    <mat-option *ngFor="let company of companies" [value]="company">{{company.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="data.__companyType === 'existing'">
                <mat-label>Plant</mat-label>
                <mat-select name="plant" [(ngModel)]="data.plant" placeholder="Plant">
                    <mat-option *ngFor="let plant of data.company.Plants" [value]="plant">{{plant.description}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="data.__companyType === 'new'">
                <input matInput name="companyName" [(ngModel)]="data.company.name" placeholder="Company name" />
            </mat-form-field>
            <mat-form-field *ngIf="data.__companyType === 'new'">
                <mat-label>Provider</mat-label>
                <mat-select name="provider" [(ngModel)]="data.provider" placeholder="Provider">
                    <mat-option [value]="1">TechMass</mat-option>
                    <mat-option [value]="2">TeamSystem</mat-option>
                </mat-select>
            </mat-form-field>
            <br>
            <hr>
            <!-- Manager -->
            <br>
            <mat-form-field>
                <mat-label>Session Type</mat-label>
                <mat-select name="sessionType" [(ngModel)]="data.sessionType" placeholder="Session Type">
                    <!-- <mat-option [value]="0">Start with piece</mat-option> -->
                    <mat-option [value]="1">Start with product</mat-option>
                    <mat-option [value]="4">Instant change over</mat-option>
                    <mat-option [value]="2">Timer Mode</mat-option>
                    <mat-option [value]="3">Timer Mode with threshold</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button (click)="onClose('save')" tabindex="2" i18n>Save</button>
        <button mat-raised-button (click)="onClose('')" tabindex="-1" i18n>Close</button>
    </div>
</div>