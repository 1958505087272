<div class="tags-page">
    <mat-form-field class="machine-select" *ngIf="isAdmin">
        <mat-select placeholder="{{'devices.columns.company' | translate}}" class="machine-select"
            [(ngModel)]="selectedCompany" (ngModelChange)="companyChanged()">
            <mat-option *ngFor="let company of companies" [value]="company">{{company.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <ngx-datatable [rows]="tmpTags" [columns]="columns" [columnMode]="'force'" [headerHeight]="80" [rowHeight]="60"
        [scrollbarV]="true" class="data-table">
    </ngx-datatable>

</div>


<ng-template #addItem ngx-datatable-header-template>
    <div class="add-new-element-button" (click)="add()" [ngClass]="{'disabled': editRow || editRow === 0}">
        <i class="fa fa-plus"></i>
        <span>{{'tags.addNew' | translate}}</span>
    </div>
</ng-template>

<ng-template #label let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="name-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <span *ngIf="editRow !== rowIndex" class="tag-name">{{row.name}}</span>
        <mat-form-field *ngIf="editRow === rowIndex">
            <input matInput type="text" [(ngModel)]="row.name" (ngModelChange)="setModified()">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #stops let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="stop-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <span *ngIf="row.Errors" class="stop-number">{{row.Errors.length}}</span>
        <span *ngIf="!row.Errors" class="stop-number">0</span>
    </div>
</ng-template>

<ng-template #actions let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="buttons-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <button mat-mini-fab (click)="back(rowIndex)" *ngIf="editRow === rowIndex">
            <mat-icon class="fa fa-chevron-left"></mat-icon>
        </button>
        <button mat-mini-fab class="red-button" (click)="delete(row, rowIndex)" *ngIf="editRow === rowIndex">
            <mat-icon class="fa fa-trash-o"></mat-icon>
        </button>
        <button mat-mini-fab class="green-button" (click)="save(row, rowIndex)" *ngIf="editRow === rowIndex"
            [disabled]="!modified || row.name.length === 0">
            <mat-icon class="fa fa-floppy-o"></mat-icon>
        </button>
        <button mat-mini-fab *ngIf="editRow !== rowIndex" (click)="edit(rowIndex)">
            <mat-icon class="fa fa-pencil"></mat-icon>
        </button>
    </div>
</ng-template>