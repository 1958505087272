<div class="operators-page">
    <mat-form-field class="machine-select" *ngIf="plants && plants.length > 1">
        <mat-select placeholder="{{'quality-controls.plants' | translate}}"
                    class="machine-select"
                    [(ngModel)]="selectedPlant"
                    (ngModelChange)="plantChanged()">
            <mat-option *ngFor="let plant of plants" [value]="plant">{{plant.description}}</mat-option>
        </mat-select>
    </mat-form-field>

    <ngx-datatable [rows]="tmpOperators" [columns]="columns" [columnMode]="'force'" [headerHeight]="50"
        [scrollbarV]="true" [rowHeight]="60" class="data-table" *ngIf="moduleActivated && !lockRequest">
    </ngx-datatable>

    <div *ngIf="!moduleActivated && !lockRequest" class="not-available">{{'operators.not-available' | translate}}</div>
</div>


<ng-template #addItem ngx-datatable-header-template>
    <div class="add-new-element-button" (click)="createOrUpdateOperator()">
        <i class="fa fa-plus"></i>
        <span> {{ 'users.addNew' | translate}}</span>
    </div>
</ng-template>

<ng-template #devicesTemplate let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div>
        <button *ngIf="row.Devices.length < 2" mat-button (click)="openDialogDevices(row, rowIndex)" class="btn-device">
            {{row.Devices.length}} {{'orders-list.columns.device' | translate}}
        </button>
        <button *ngIf="row.Devices.length > 1" mat-button (click)="openDialogDevices(row, rowIndex)" class="btn-device">
            {{row.Devices.length}} {{'orders-list.columns.devices' | translate}}
        </button>
    </div>
</ng-template>

<!-- <ng-template #enabled let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="slide-toggle-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <div *ngIf="!isEditRow(rowIndex)">
            <span>
                <i class="fa fa-check-circle-o" *ngIf="row.enabled"></i>
            </span>
        </div>
        <mat-slide-toggle
            [(ngModel)]="row.enabled"
            (ngModelChange)="setModified(rowIndex)"
            *ngIf="isEditRow(rowIndex)">
        </mat-slide-toggle>
    </div>
</ng-template> -->

<ng-template #actions let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="buttons-container">
        <button mat-mini-fab [center-mat-menu]="menuTrigger">
            <mat-icon class="fa fa-ellipsis-h"></mat-icon>
        </button>
        <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
            <mat-menu #menu="matMenu" class="popover-menu">
                <button mat-menu-item class="popover-menu-item" (click)="createOrUpdateOperator(row.id)">
                    <i class="fa fa-pencil"></i>
                    {{'control.modify' | translate}}
                </button>
                <button mat-menu-item class="popover-menu-item" (click)="deleteOperator(row.id)">
                    <i class="fa fa-trash"></i>
                    {{'control.delete' | translate}}
                </button>
            </mat-menu>
        </div>
    </div>
</ng-template>