<div class="new-product-page">
    <div class="content">
        <div class="header">
            <div class="top-row">
                <span>{{'products.title' | translate}}</span>
                <div class="arrow-container">
                    <div class="arrow"></div>
                </div>
                <span *ngIf="!isEditing && productSupplyType == 1"> {{'products.newEditProduct.title' | translate}} - {{'products.newEditProduct.product' | translate}}</span>
                <span *ngIf="!isEditing && productSupplyType != 1"> {{'products.newEditProduct.title' | translate}} - {{'products.type.rawMaterial' | translate}}</span>
                <span *ngIf="isEditing"> {{'products.newEditProduct.titleEditing' | translate}}</span>
            </div>
            <div class="bottom-row">
                <div class="bottom-arrow-wrp">
                    <ts-button
                        [type]="'primary small white'"
                        icon="arrow-left"
                        (buttonClicked)="handleCancelClicked()"
                    ></ts-button>
                </div>
                <div class="submenu-label-wrp">
                    <span *ngIf="!isEditing">{{'products.newEditProduct.title' | translate}}</span>                
                    <span *ngIf="isEditing">{{'products.newEditProduct.titleEditing' | translate}}</span>                
                </div>
            </div>
        </div>    
        <div class="content-wrp">
            <div class="left-wrp">
                <span>{{'products.newEditProduct.data' | translate}}</span>
                <div class="menu-list">
                    <span [ngClass]="{'selected': selectedMenu == 1}" (click)="handleMenuClicked(1)">{{'products.newEditProduct.articleRegistry' | translate}}</span>
                    <span  [ngClass]="{'selected': selectedMenu == 2}" (click)="handleMenuClicked(2)">{{'products.newEditProduct.advancedInfo' | translate}}</span>
                    <span *ngIf="productSupplyType == 1" [ngClass]="{'selected': selectedMenu == 3}" (click)="handleMenuClicked(3)">{{'products.newEditProduct.associatedResources' | translate}}</span>
                </div>
            </div>

            <div class="right-wrp" (scroll)="onRightScroll($event)" #right_wrp>
                <div class="section registry">
                    <span class="title">{{'products.newEditProduct.articleRegistry' | translate}}</span>
                    <form [formGroup]="registryForm" *ngIf="registryForm">

                        <div class="input-row">
                            <ts-select *ngIf="productSupplyType == 1" class="select fw400" [data]="selectProductTypeData" [clearable]="true"
                                placeholderText="{{'products.newEditProduct.articleType' | translate}}"
                                formControlName="type"
                                [enabled]="true"
                                [showErrors]="true"
                                [value]="productCreationInfo ? selectProductTypeDataValue : null"
                                primaryText="{{'products.newEditProduct.articleType' | translate}}*"
                                width="298px"
                                [validationDict]="customValidationDict | translateJSON | async">
                            </ts-select>
                            <div class="articleCodeWrapper">
                                <ts-text-box class="fw400" primaryText="{{'products.newEditProduct.articleCode' | translate}}*"
                                placeholderText="{{'products.newEditProduct.articleCode' | translate}}" formControlName="code" (valueChanged)="handleCodeChanged()"
                                [showErrors]="true" width="298px" [enabled]="!isEditing" [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
                                <div *ngIf="isDuplicate && this.registryForm.get('code').value && this.registryForm.get('code').value == codeValueOriginal" [ngClass]="isDuplicateHasErrors?.code ? 'error info' : 'info'">
                                    <ts-avatar [icon]="!isDuplicateHasErrors?.code ? 'info-square' : 'times-octagon'" little="true" customStyles="background: transparent; width: 25px; height: 25px; transform: scale(0.8);"></ts-avatar> 
                                    <span *ngIf="!isDuplicateHasErrors?.code">{{'products.newEditProduct.articleCodeAlert' | translate}}</span>
                                    <span *ngIf="isDuplicateHasErrors?.code">{{'products.newEditProduct.articleCodeError' | translate}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="input-row-b">
                            <div class="articleDescriptionWrapper">
                                <vapor-angular-text-area class="select text-area fw400"
                                    [minLength]="1"
                                    [maxLength]="100"
                                    placeholderText="{{'products.newEditProduct.articleDescription' | translate}}"
                                    formControlName="description"
                                    primaryText="{{'products.newEditProduct.articleDescription' | translate}}*"
                                    width="612px"
                                    (valueChanged)="handleDescriptionChanged()"
                                    [showErrors]="true"
                                    [validationDict]="customValidationDict | translateJSON | async">
                                </vapor-angular-text-area>
                                <div *ngIf="isDuplicate && this.registryForm.get('description').value && this.registryForm.get('description').value == descriptionValueOriginal" [ngClass]="isDuplicateHasErrors?.description ? 'error info' : 'info'">
                                    <ts-avatar [icon]="!isDuplicateHasErrors?.description ? 'info-square' : 'times-octagon'" little="true" customStyles="background: transparent; width: 25px; height: 25px; transform: scale(0.8);"></ts-avatar> 
                                    <span *ngIf="!isDuplicateHasErrors?.description">{{'products.newEditProduct.articleDescriptionAlert' | translate}}</span>
                                    <span *ngIf="isDuplicateHasErrors?.description">{{'products.newEditProduct.articleDescriptionError' | translate}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="input-row">
                            <ts-select class="select fw400" [data]="selectProductMesureUnitsData" [clearable]="true"
                                placeholderText="{{'products.newEditProduct.misureUnit' | translate}}"
                                formControlName="measure"
                                [enabled]="true"
                                [showErrors]="true"
                                primaryText="{{'products.newEditProduct.misureUnit' | translate}}*"
                                [value]="productCreationInfo ? selectProductMesureUnitsDataValue : null"
                                width="188px"
                                (valueChange)="onUnitChange($event)"
                                [validationDict]="customValidationDict | translateJSON | async">
                            </ts-select>
                            <ts-select class="select" [data]="selectProductFamiliesData" [clearable]="true"
                                placeholderText="{{'products.newEditProduct.articleFamily' | translate}}"
                                formControlName="family"
                                [enabled]="true"
                                [showErrors]="true"
                                primaryText="{{'products.newEditProduct.articleFamily' | translate}}"
                                [value]="productCreationInfo ? selectProductFamiliesDataValue : null"
                                width="408px">
                            </ts-select>
                        </div>

                        <div class="additional-info-wrp" [ngClass]="{'open': additionalInfoOpen, 'closed': !additionalInfoOpen}">
                            <ts-accordion-panel #accordion
                                title="{{'products.newEditProduct.additionalInfo' | translate}}" 
                                [opened]="additionalInfoOpen" 
                                (toggle)="toggleAdditionalInfo($event)">
                                <div class="accordionContent">
                                    <form [formGroup]="additionalInfoForm" *ngIf="additionalInfoForm">
                                        <div formArrayName="keyValueArray">
                                            <ng-container *ngFor="let info of keyValueArray.controls; let i = index " [formGroupName]="i">
                                                <div class="input-row accordion">
                                                    <ts-text-box primaryText="{{'products.newEditProduct.description' | translate}}*"
                                                    placeholderText="{{'products.newEditProduct.description' | translate}}" formControlName="key"
                                                    [validationDict]="customValidationDict | translateJSON | async"
                                                    [showErrors]="true" width="257px"></ts-text-box>

                                                    <ts-text-box primaryText="{{'products.newEditProduct.value' | translate}}*"
                                                    placeholderText="{{'products.newEditProduct.value' | translate}}" formControlName="value"
                                                    [validationDict]="customValidationDict | translateJSON | async"
                                                    [showErrors]="true" width="257px"></ts-text-box>

                                                    <ts-button
                                                        [type]="'tertiary medium alert'"
                                                        icon="trash-alt"
                                                        iconSize="1x" 
                                                        (buttonClicked)="handleRemoveInfoButtonClicked($event, i)"
                                                    ></ts-button>                              
                                                </div>
                                            </ng-container>
                                        </div>
                                    </form>                                    
                                    <div class="addInfoRow">
                                        <ts-button
                                        [type]="'tertiary medium blue'"
                                        text="{{'products.newEditProduct.addInfo' | translate}}"
                                        icon="plus-circle"
                                        iconSize="1x" 
                                        (buttonClicked)="handleNewInfoButtonClicked()"
                                        ></ts-button>                                
                                    </div>
                                </div>
                            </ts-accordion-panel>
                            
                        </div>
                    </form>
                </div>

                <div class="divider" #dividerA></div>
                <div *ngIf="productSupplyType == 1" class="section advancedInfo">
                    <div class="title-row">
                        <span class="title">{{'products.newEditProduct.advancedInfo' | translate}}</span>
                    </div>                
                    <form [formGroup]="advancedInfoForm" *ngIf="advancedInfoForm">
                        <div class="input-row">
                            <span class="primary">{{'products.newEditProduct.articleVariationsGroups' | translate}}</span>
                        </div>
                        <div class="input-row">
                            <ts-simple-select #variationsSelect class="select" [data]="variationsGroups" [multiSelect]="true" [clearable]="true"
                            formControlName="variationsGroupArray"
                            (valueChange)="handleVariationsGroupChanged($event)"
                            [showErrors]=""></ts-simple-select>                    
                        </div>
                    </form>
                </div>
                
                <div class="divider" #dividerB></div>
                <div *ngIf="productSupplyType == 1" class="section resources">
                    <div class="title-row">
                        <span class="title">{{'products.newEditProduct.associatedResources' | translate}}</span>
                    </div>
                        <div class="input-row">
                            <ts-simple-select #resourcesSelect class="select" [data]="resources" [multiSelect]="true" [clearable]="true"
                            (valueChange)="handleResourcesChanged($event)"
                            [showErrors]=""
                            placeholderText="{{'products.newEditProduct.resources' | translate}}"></ts-simple-select>
                        </div>
                        <div class="resources-wrp">
                            <div class="absolute-values-container" *ngIf="absoluteValuesOpen">
                            </div>
                            <form [formGroup]="resourcesForm" *ngIf="resourcesForm">
                                <div formArrayName="resourcesFormArray">                        
                                    <ng-container *ngFor="let info of resourcesFormArray.controls; let i = index " [formGroupName]="i">
                                        <div class="resource-values-wrp">
                                            <div class="title-wrp">
                                                <ts-accordion-panel
                                                    class="res-accordion"
                                                    title="{{selectedResources[i]?.val}}" 
                                                    [opened]="!selectedResources[i]?.collapsed" 
                                                    (toggle)="collapseResourceRow($event,selectedResources[i]?.id)">                                                         
                                                </ts-accordion-panel>          
                                                <ts-button
                                                    class="resource-trash"
                                                    [type]="'tertiary medium alert'"
                                                    icon="trash-alt"
                                                    iconSize="1x" 
                                                    (buttonClicked)="handleRemoveResourceClicked($event, i)">
                                                </ts-button>                    
                                            </div> 
                    
                                            <div *ngIf="!selectedResources[i]?.collapsed" class="resource-values-row fw400">
                                                <ts-number-box type="number" primaryText="{{'products.values.multiplier' | translate}} {{ this.registryForm.get('measure').value?.val ? '(' + this.registryForm.get('measure').value?.val + ')' : '' }}"
                                                [showErrors]="true" formControlName="multiplier" placeholderText="{{'products.values.multiplier' | translate}}"
                                                width="188px" [validationDict]="customValidationDict | translateJSON: {min: minMultiplierValue} | async"></ts-number-box>     
                                                <ts-number-box primaryText="{{'products.values.scrapsMultiplier' | translate}} {{ this.registryForm.get('measure').value?.val ? '(' + this.registryForm.get('measure').value?.val + ')' : '' }}"
                                                [showErrors]="true" formControlName="scrapsMultiplier" placeholderText="{{'products.values.scrapsMultiplier' | translate}}"
                                                width="188px" [validationDict]="customValidationDict | translateJSON: {min: minMultiplierValue} | async"></ts-number-box>                                       
                                                <ts-number-box primaryText="{{'products.values.inputMultiplier' | translate}} {{ this.registryForm.get('measure').value?.val ? '(' + this.registryForm.get('measure').value?.val + ')' : '' }}"
                                                [showErrors]="true" formControlName="inputMultiplier" placeholderText="{{'products.values.inputMultiplier' | translate}}"
                                                width="188px" [validationDict]="customValidationDict | translateJSON: {min: minMultiplierValue} | async"></ts-number-box>                                       
                                                <ts-number-box primaryText="{{'products.values.targetSpeed' | translate}} {{ this.registryForm.get('measure').value?.val ? '(' + this.registryForm.get('measure').value?.val + '/min)' : '' }}"
                                                [showErrors]="true" formControlName="targetSpeed" placeholderText="{{'products.values.targetSpeed' | translate}}"
                                                width="188px" step="0.001" [validationDict]="customValidationDict | translateJSON: {min: 0} | async"></ts-number-box>                                       
                                            </div>
                                            <div *ngIf="!selectedResources[i]?.collapsed" class="resource-values-row fw400">
                                                <ts-number-box primaryText="{{'products.values.startThreshold' | translate}} {{ this.registryForm.get('measure').value?.val ? '(' + this.registryForm.get('measure').value?.val + ')' : '' }}"
                                                [showErrors]="true" formControlName="startThreshold" placeholderText="{{'products.values.startThreshold' | translate}}"
                                                width="188px" [validationDict]="customValidationDict | translateJSON: {min: 1} | async"></ts-number-box>                                       
                                                <ts-number-box primaryText="{{'products.values.stopThreshold' | translate}} {{'(sec)'}}"
                                                [showErrors]="true" formControlName="stopThreshold" placeholderText="{{'products.values.stopThreshold' | translate}}"
                                                width="188px" [validationDict]="customValidationDict | translateJSON: {min: 1} | async"></ts-number-box>                                       
                                                <ts-number-box primaryText="{{'products.values.divider' | translate}} {{ this.registryForm.get('measure').value?.val ? '(' + this.registryForm.get('measure').value?.val + ')' : '' }}"
                                                [showErrors]="true" formControlName="divider" placeholderText="{{'products.values.divider' | translate}}"
                                                width="188px" [validationDict]="customValidationDict | translateJSON: {min: 1} | async"></ts-number-box>                                       
                                                <ts-number-box primaryText="{{'products.values.defaultQuantity' | translate}} {{ this.registryForm.get('measure').value?.val ? '(' + this.registryForm.get('measure').value?.val + ')' : '' }}"
                                                [showErrors]="true" formControlName="defaultQuantity" placeholderText="{{'products.values.defaultQuantity' | translate}}"
                                                width="188px" [validationDict]="customValidationDict | translateJSON: {min: 0} | async"></ts-number-box>                                       
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </form>
                        </div>
                </div>
            </div>
        </div>
    </div>

    <app-generic-dialog *ngIf="showSaveModal"  [data]="modalData" (primaryButtonClicked)="saveProduct()" (cancelButtonClicked)="closeAllModals()"></app-generic-dialog>
    <app-generic-dialog *ngIf="showCancelModal"  [data]="modalData" (primaryButtonClicked)="saveProductAndExit()" (secondaryButtonClicked)="goBack()" (cancelButtonClicked)="closeAllModals()"></app-generic-dialog>
    <app-generic-dialog *ngIf="showBackModal"  [data]="modalData" (primaryButtonClicked)="goBack()" (cancelButtonClicked)="closeAllModals()"></app-generic-dialog>
    
    <div class="footer">
        <div>
            <ts-button class="footer-btn"
                [type]="'secondary small blue'"
                (buttonClicked)="handleCancelClicked()"
                text="{{'products.footer.cancel' | translate}}"
                height="48px">
            </ts-button>
            <ts-button class="footer-btn"
                [type]="'secondary small white'"
                (buttonClicked)="handleSaveClicked()"
                text="{{'products.footer.save' | translate}}"
                height="48px">
         </ts-button>
        </div>
    </div>
</div>
