<div class="gen-dialog-wrp">
    <div class="container">
        <div class="title-row">
            {{data.title}}        
        </div>
        <div class="content-row">
            {{data.message}}
        </div>
        <div *ngIf="data.mode == 'save'" class="buttons-row">
            <ts-button class="cancel" (buttonClicked)="onButtonClicked('cancel')" text="{{'dialogs.cancel' | translate}}" [type]="'tertiary medium blue'"></ts-button>
            <ts-button class="save" (buttonClicked)="onButtonClicked('primary')" text="{{'dialogs.save' | translate}}" [type]="'secondary medium blue'"></ts-button>
        </div>
        <div *ngIf="data.mode == 'exit-save'" class="buttons-row exit-save">
            <ts-button class="cancel" (buttonClicked)="onButtonClicked('cancel')" text="{{'dialogs.cancel' | translate}}" [type]="'tertiary medium blue'"></ts-button>
            <ts-button (buttonClicked)="onButtonClicked('secondary')" text="{{'dialogs.noSaveAndExit' | translate}}" [type]="'secondary medium alert'"></ts-button>            
            <ts-button class="save" (buttonClicked)="onButtonClicked('primary')" text="{{'dialogs.saveAndExit' | translate}}" [type]="'secondary medium blue'"></ts-button>            
        </div>
        <div *ngIf="data.mode == 'cancel'" class="buttons-row">
            <ts-button class="cancel" (buttonClicked)="onButtonClicked('cancel')" text="{{'dialogs.cancel' | translate}}" [type]="'tertiary medium blue'"></ts-button>
            <ts-button (buttonClicked)="onButtonClicked('primary')" text="{{'dialogs.confirm' | translate}}" [type]="'primary medium alert'"></ts-button>            
        </div>
        <div *ngIf="data.mode == 'delete'" class="buttons-row">
            <ts-button  class='cancel' (buttonClicked)="onButtonClicked('cancel')" text="{{'dialogs.cancel' | translate}}" [type]="'tertiary medium blue'"></ts-button>
            <ts-button  class='delete' (buttonClicked)="onButtonClicked('primary')" text="{{'dialogs.del' | translate}}" [type]="'primary medium alert'"></ts-button>            
        </div>
    </div>
</div>