<div class="loader-container" *ngIf="lockRequest">
    <img src={{getLoaderSrc()}}>
</div>
<div class="plugin-page">
    <mat-form-field class="machine-select">
        <mat-label>{{'devices.single' | translate}}</mat-label>
        <mat-select [(ngModel)]="selectedDevice" (ngModelChange)="deviceChanged()">
            <mat-option *ngFor="let device of devices" [value]="device">{{device.label}}
                ({{ device.Company ? device.Company.name : '?' }})</mat-option>
        </mat-select>
    </mat-form-field>

    <ngx-datatable
        [rows]="tmpPlugins"
        [columns]="columns"
        [columnMode]="'force'"
        [headerHeight]="50"
        [rowHeight]="80"
        [scrollbarV]="true"
        class="data-table">
    </ngx-datatable>
</div>

<ng-template #addItem ngx-datatable-header-template>
    <div class="add-new-element-button" (click)="addPlugin()" [ngClass]="{'disabled': editRow || editRow === 0}">
        <i class="fa fa-plus"></i>
        <span>{{'plugin.columns.addNew' | translate}}</span>
    </div>
</ng-template>

<ng-template #code let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <span *ngIf="!isEditRow(rowIndex)" class="tag-name">{{row.code}}</span>
        <mat-form-field *ngIf="isEditRow(rowIndex)">
            <input matInput type="text" [(ngModel)]="row.code" (ngModelChange)="setModified(rowIndex)">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #enabled let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="slide-toggle-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <div *ngIf="!isEditRow(rowIndex)">
            <span>
                <i class="fa fa-check-circle-o" *ngIf="row.PluginsDevices.enabled"></i>
            </span>
        </div>
        <mat-slide-toggle [(ngModel)]="row.PluginsDevices.enabled" (ngModelChange)="setModified(rowIndex)" *ngIf="isEditRow(rowIndex)">
        </mat-slide-toggle>
    </div>
</ng-template>

<ng-template #isOnSatellite let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="slide-toggle-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <div *ngIf="!isEditRow(rowIndex)">
            <span>
                <i class="fa fa-check-circle-o" *ngIf="row.PluginsDevices.isOnSatellite"></i>
            </span>
        </div>
        <mat-slide-toggle [(ngModel)]="row.PluginsDevices.isOnSatellite" (ngModelChange)="setModified(rowIndex)" *ngIf="isEditRow(rowIndex)">
        </mat-slide-toggle>
    </div>
</ng-template>

<ng-template #description let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <span *ngIf="!isEditRow(rowIndex)" class="tag-name">{{row.description}}</span>
        <mat-form-field *ngIf="isEditRow(rowIndex)">
            <input matInput type="text" [(ngModel)]="row.description" (ngModelChange)="setModified(rowIndex)">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #actions let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="buttons-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <button mat-mini-fab (click)="back(rowIndex)" *ngIf="isEditRow(rowIndex)">
            <mat-icon class="fa fa-chevron-left"></mat-icon>
        </button>
        <button mat-mini-fab class="green-button" (click)="save(row, rowIndex)" *ngIf="isEditRow(rowIndex)"
            [disabled]="!isModified() || row.code.length === 0 || row.description.length === 0">
            <mat-icon class="fa fa-floppy-o"></mat-icon>
        </button>
        <button mat-mini-fab *ngIf="!isEditRow(rowIndex)" (click)="edit(rowIndex)">
            <mat-icon class="fa fa-pencil"></mat-icon>
        </button>
    </div>
</ng-template>
