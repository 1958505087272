<form [formGroup]="form" [autocomplete]="false" class="container new-order-drawer mic-drawer">
    <div class="row">
        <ts-h4 [weight]="600">{{'orders-list.columns.status' | translate}}</ts-h4>
    </div>
    <div class="row">
        <div class="column">
            <ts-select [data]="statuses" primaryText="{{'orders-list.columns.status' | translate}}"
                       placeholderText="{{'orders-list.columns.status' | translate}}" [showErrors]="true"
                       formControlName="status" [value]="f.status.value" [enabled]="!order?.groupId" 
                       [validationDict]="customValidationDict | translateJSON | async"></ts-select>
        </div>
        <div class="column">
            &nbsp;
        </div>
    </div>
    <div class="empty-row"></div>
    <div class="row">
        <ts-h4 [weight]="600">{{'orders-list.data' | translate}}</ts-h4>
    </div>
    <div class="row">
        <div class="column">
            <ts-text-box primaryText="{{'orders-list.columns.code' | translate}}" [enabled]="!isEditing"
                         placeholderText="{{'orders-list.columns.code' | translate}}" formControlName="code"
                         [showErrors]="true" width="300px" [validationDict]="customValidationDict | translateJSON | async"></ts-text-box>
        </div>
        <div class="column">
            <!--
                    Vapor sets dx-date-box isValid without checking if the control is touched,
                    but this results in always showing an error: the long term solution would be
                    to fix Vapor, meanwhile we check the control ourselves
                -->
            <ts-datepicker mode="date" primaryText="{{'orders-list.columns.date' | translate}}"
                           placeholderText="{{'orders-list.columns.date' | translate}}"
                           [showErrors]="f.date.invalid && f.date.touched" width="300px"
                           formControlName="date" [validationDict]="customValidationDict | translateJSON | async"></ts-datepicker>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <ts-number-box primaryText="{{'orders-list.columns.production-target' | translate}}"
                           placeholderText="{{'orders-list.pieces' | translate}}" infoMode="info"
                           infoLabel="{{'orders-list.pieces' | translate}}" primaryIcon="" [min]="0"
                           formControlName="target" [width]="300" [showErrors]="true"
                           [validationDict]="customValidationDict | translateJSON: {min: 0} | async"></ts-number-box>
        </div>
        <div class="column">
            <ts-number-box primaryText="{{'orders-list.columns.target-change-over' | translate}}"
                           placeholderText="{{'orders-list.minutes' | translate}}" infoMode="info"
                           infoLabel="{{'orders-list.minutes' | translate}}" primaryIcon="" [min]="0" [width]="300"
                           formControlName="targetChangeOver" [showErrors]="true"
                           [validationDict]="customValidationDict | translateJSON: {min:0} | async"></ts-number-box>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <ts-autocomplete #product primaryText="{{'orders-list.columns.product-description' | translate}}"
                             placeholder="{{'orders-list.columns.product-description' | translate}}" prefix="" suffix=""
                             formControlName="product" width="300px" [showErrors]="true"
                             (valueChange)="productChanged($event)"
                             [enabled]="!order?.groupId && !isEditing" [validationDict]="customValidationDict | translateJSON | async"></ts-autocomplete>
        </div>
        <div class="column">
            <div class="ts-input-container" width="300px">
                <div class="ts-input-container__header">
                    <div class="ts-input-container__header__primary">
                        <span class="ts-input-container__header__primary__text">
                            {{'orders-list.columns.devices' | translate}}
                        </span>
                    </div>
                    <span class="ts-input-container__header__optional"></span>
                </div>
                <div class="ts-input-container__data">
                    <!--
                            Vapor sets the ts-invalid class when showErrors is true regardless of whether the
                            control is invalid and touched like other components, such as ts-autocomplete,
                            do: the long term solution would be to fix Vapor, meanwhile we check the control
                            ourselves
                        -->
                    <ts-simple-select [data]="devices" [multiSelect]="true" [clearable]="true"
                                      [showErrors]="f.devices.invalid && f.devices.touched"
                                      placeholderText="{{'orders-list.columns.devices' | translate}}"
                                      formControlName="devices"
                                      [enabled]="!order?.groupId"
                                      [validationDict]="customValidationDict | translateJSON | async"></ts-simple-select>
                </div>
            </div>
        </div>
    </div>
    <div class="empty-row"></div>
    <ts-accordion [allowMulti]="false">
        <ts-accordion-panel title="{{'orders-list.additional-info' | translate}}" [opened]="additionalInfoOpen"
                            (toggle)="onAdditionalInfoToggle($event)">
            <ng-container formArrayName="jsonInfoArray">
                <div class="jsonInfoRow" *ngFor="let info of jsonInfoArray.controls; let i = index"
                     [formGroupName]="i.toString()">
                    <ts-text-box primaryText="{{'orders-list.jsonInfo.key' | translate}}"
                                 placeholderText="{{'orders-list.jsonInfo.insertKey' | translate}}" [width]="250"
                                 formControlName="key"></ts-text-box>
                    <ts-text-box primaryText="{{'orders-list.jsonInfo.value' | translate}}"
                                 placeholderText="{{'orders-list.jsonInfo.insertValue' | translate}}" [width]="250"
                                 formControlName="value"></ts-text-box>
                    <ts-button type="tertiary alert-dark medium" icon="trash" (click)="deleteJsonInfo(i)"></ts-button>
                </div>
            </ng-container>
            <div class="jsonInfoRow">
                <ts-button type="tertiary small blue" icon="plus" text="{{'orders-list.jsonInfo.addNew' | translate}}"
                           (click)="addNewJsonInfo()"></ts-button>
            </div>
        </ts-accordion-panel>
    </ts-accordion>
</form>