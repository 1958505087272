<div class="loader-container" *ngIf="lockRequest">
    <img src="{{getLoaderSrc()}}">
</div>
<div class="quality-controls-page">
    <mat-form-field class="machine-select" *ngIf="plants && plants.length > 1">
        <mat-select placeholder="{{'quality-controls.plants' | translate}}"
                    class="machine-select"
                    [(ngModel)]="selectedPlant"
                    (ngModelChange)="plantChanged()">
            <mat-option *ngFor="let plant of plants" [value]="plant">{{plant.description}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="machine-select">
        <mat-select placeholder="{{'quality-controls.filter-products' | translate}}"
                    class="machine-select"
                    [(ngModel)]="selectedProduct"
                    (ngModelChange)="productChanged()">
            <mat-option [value]="-1">{{ 'quality-controls.all-controls' | translate }}</mat-option>
            <mat-option *ngFor="let product of products" [value]="product">{{product.name + ' - ' + product.code}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <ngx-datatable [rows]="controls" [columns]="columns" [columnMode]="'force'" [headerHeight]="50" [rowHeight]="60"
        [scrollbarV]="true" class="data-table data-table-qa">
    </ngx-datatable>

    <ng-template #addItem ngx-datatable-header-template>
        <div class="add-new-element-button" (click)="addControl()" [ngClass]="{'disabled': editRow || editRow === 0}">
            <i class="fa fa-plus"></i>
            <span> {{ 'quality-controls.addNew' | translate}}</span>
        </div>
    </ng-template>

    <ng-template #description let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
        <span *ngIf="row.description" class="prod-label">{{row.description}}</span>
        <span *ngIf="!row.description"
            class="prod-label">{{ 'quality-controls.columns.no-description' | translate}}</span>
    </ng-template>

    <ng-template #scheduling let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
        <button mat-button (click)="openDialog(row.id, 'schedulings')" class="btn-device"
            *ngIf="row.ControlSchedulings && row.ControlSchedulings.length > 1">
            {{row.ControlSchedulings.length}} {{ 'quality-controls.columns.schedulings' | translate}}
        </button>
        <button mat-button (click)="openDialog(row.id, 'scheduling')" class="btn-device"
            *ngIf="row.ControlSchedulings && row.ControlSchedulings.length === 1">
            {{row.ControlSchedulings.length}} {{ 'quality-controls.columns.scheduling' | translate}}
        </button>
        <span *ngIf="row.ControlSchedulings && row.ControlSchedulings.length < 1"
            class="prod-label">{{ 'quality-controls.columns.none' | translate}}</span>
    </ng-template>

    <ng-template #device let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
        <button mat-button (click)="openDialog(row.id, 'devices')" class="btn-device" *ngIf="row.Devices && row.Devices.length > 1">
            {{row.Devices.length}} {{ 'quality-controls.columns.devices' | translate}}
        </button>
        <button mat-button (click)="openDialog(row.id, 'device')" class="btn-device" *ngIf="row.Devices && row.Devices.length === 1">
            {{row.Devices.length}} {{ 'quality-controls.columns.device' | translate}}
        </button>
        <button mat-button (click)="openDialog(row.id, 'all_devices')" class="btn-device" *ngIf="row.allDevices && devices && devices.length > 1">
            {{ 'control.all-devices' | translate}}
        </button>
        <button mat-button (click)="openDialog(row.id, 'all_devices')" class="btn-device" *ngIf="row.allDevices && devices && devices.length === 1">
            {{devices.length}} {{ 'quality-controls.columns.devices' | translate}}
        </button>
        <span *ngIf="row.Devices && row.Devices.length < 1 && !row.allDevices" class="prod-label">
            {{ 'quality-controls.columns.none' | translate}}
        </span>
    </ng-template>

    <ng-template #product let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
        <button mat-button (click)="openDialog(row.id, 'products')" class="btn-device" *ngIf="row.Products && row.Products.length > 1">
            {{row.Products.length}} {{ 'quality-controls.columns.products' | translate}}
        </button>
        <button mat-button (click)="openDialog(row.id, 'product')" class="btn-device" *ngIf="row.Products && row.Products.length === 1">
            {{row.Products.length}} {{ 'quality-controls.columns.product' | translate}}
        </button>
        <button mat-button (click)="openDialog(row.id, 'all_products')" class="btn-device" *ngIf="row.allProducts">
            {{ 'control.all-products' | translate}}
        </button>
        <span *ngIf="row.Products && row.Products.length < 1 && !row.allProducts" class="prod-label">
            {{ 'quality-controls.columns.none' | translate}}
        </span>
    </ng-template>

    <ng-template #task let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
        <button mat-button (click)="openDialog(row.id, 'tasks')" class="btn-device" *ngIf="row.Tasks && row.Tasks.length > 1">
            {{row.Tasks.length}} {{ 'quality-controls.columns.tasks' | translate}}
        </button>
        <button mat-button (click)="openDialog(row.id, 'task')" class="btn-device" *ngIf="row.Tasks && row.Tasks.length === 1">
            {{row.Tasks.length}} {{ 'quality-controls.columns.task' | translate}}
        </button>
        <span *ngIf="row.Tasks && row.Tasks.length < 1" class="prod-label">{{ 'quality-controls.columns.none' | translate}}</span>
    </ng-template>

    <ng-template #enabled let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <span>
            <i class="fa fa-check-circle-o" (click)="enablingControl(row.id)"
                [ngClass]="row.enabled ? 'enabled' : 'disabled'"></i>
            <!-- <i class="fa fa-times-circle-o" *ngIf="!row.enabled"></i> -->
        </span>
    </ng-template>

    <ng-template #actions let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <button mat-mini-fab [center-mat-menu]="menuTrigger">
            <mat-icon class="fa fa-ellipsis-h"></mat-icon>
        </button>
        <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
            <mat-menu #menu="matMenu" class="popover-menu-qa">
                <button mat-menu-item class="popover-menu-item" (click)="edit(row)">
                    <i class="fa fa-pencil"></i>
                    {{'control.modify' | translate}}
                </button>
                <button mat-menu-item class="popover-menu-item" (click)="duplicate(row.id)">
                    <i class="fa fa-clone"></i>
                    {{'control.duplicate' | translate}}
                </button>
                <button mat-menu-item class="popover-menu-item" (click)="delete(row.id)">
                    <i class="fa fa-trash"></i>
                    {{'control.delete' | translate}}
                </button>
            </mat-menu>
        </div>

    </ng-template>
    <router-outlet></router-outlet>
</div>
