<div class="plant-dialog">
    <div class="title-container">
        <h3>Modifica impianto</h3>
        <i class="fa fa-fw fa-times remove-description" (click)="closeDialog()"></i>
    </div>

    <div class="plant-module-container">
        <mat-form-field class="question-container">
            <input matInput #question placeholder="Nome" type="text"
                [(ngModel)]="description">
            <i matSuffix class="fa fa-fw fa-times remove-description" (click)="_removeDescription()"></i>
        </mat-form-field>

        <div class="plant-module-body">
            <div class="plant-module-option">
                <span>{{ 'companies.plantModule.performances' | translate }}</span>
                <mat-slide-toggle class="plant-module-enable"
                [(ngModel)]="plantModule.performances"></mat-slide-toggle>
            </div>
            <div class="plant-module-option">
                <span>{{ 'companies.plantModule.orders' | translate }}</span>
                <mat-slide-toggle class="plant-module-enable"
                [(ngModel)]="plantModule.orders"></mat-slide-toggle>
            </div>
            <div class="plant-module-option-suboption-container" *ngIf="plantModule">
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.isOnSatellite">
                        <span>{{ 'companies.orderConfigModule.isOnSatellite' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.autoProgress">
                        <span>{{ 'companies.orderConfigModule.autoProgress' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.validateProgress">
                        <span>{{ 'companies.orderConfigModule.validateProgress' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.workProcess">
                        <span>{{ 'companies.orderConfigModule.workProcess' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.deltaTarget">
                        <span>{{ 'companies.orderConfigModule.deltaTarget' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.targetScrapsDiff">
                        <span>{{ 'companies.orderConfigModule.targetScrapsDiff' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.showOrderList">
                        <span>{{ 'companies.orderConfigModule.showOrderList' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.multiOrder">
                        <span>{{ 'companies.orderConfigModule.multiOrder' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.batch">
                        <span>{{ 'companies.orderConfigModule.batch' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.batchRawMaterial">
                        <span>{{ 'companies.orderConfigModule.batchRawMaterial' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.scanMaterial">
                        <span>{{ 'companies.orderConfigModule.scanMaterial' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.pdfDocs">
                        <span>{{ 'companies.orderConfigModule.pdfDocs' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.startOrder">
                        <span>{{ 'companies.orderConfigModule.startOrder' | translate }}</span>
                    </mat-checkbox>
                </div>
                <div class="plant-module-option-suboption">
                    <mat-checkbox class="target-time-checkbox" [disabled]="!plantModule?.orders" [(ngModel)]="plantModule.OrderModuleConfig.endOrder">
                        <span>{{ 'companies.orderConfigModule.endOrder' | translate }}</span>
                    </mat-checkbox>
                </div>
            </div>
            <div class="plant-module-option">
                <span>{{ 'companies.plantModule.qualityControls' | translate }}</span>
                <mat-slide-toggle class="plant-module-enable"
                [(ngModel)]="plantModule.qualityControls"></mat-slide-toggle>
            </div>
            <div class="plant-module-option">
                <span>{{ 'companies.plantModule.rawMaterial' | translate }}</span>
                <mat-slide-toggle class="plant-module-enable"
                [(ngModel)]="plantModule.rawMaterial"></mat-slide-toggle>
            </div>
            <div class="plant-module-option">
                <span>{{ 'companies.plantModule.parameters' | translate }}</span>
                <mat-slide-toggle class="plant-module-enable"
                [(ngModel)]="plantModule.parameters"></mat-slide-toggle>
            </div>
            <div class="plant-module-option">
                <span>{{ 'companies.plantModule.operatorsLogin' | translate }}</span>
                <mat-slide-toggle class="plant-module-enable"
                [(ngModel)]="plantModule.operatorsLogin"></mat-slide-toggle>
            </div>
        </div>
    </div>
    <div class="footer-button">
        <button mat-raised-button (click)="closeDialog()">Annulla
        </button>
        <button mat-raised-button (click)="save()">Salva
        </button>
    </div>
</div>