import { AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FontService, NotificationService } from '@vapor/angular-ui';
import { faPlusCircle, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Subscription, of } from 'rxjs';
import { ResourceType } from '../../../models/resources.model';
import { TsTreeListColumn } from '@vapor/angular-ui-extra/tree-list/tree-list-config';
import { ResourcesService } from '../../../services/resources.service';
import { TreeListComponent } from '@vapor/angular-ui-extra';

interface Resource {
    id: number;
    parentId: number;
    resource: ResourceType;
    code: string;
    description: string;
    selected?: boolean;
}

interface Department {
    id: number;
    val: string;
}

@Component({
    selector: 'resource-drawer',
    templateUrl: './resource-drawer.component.html',
    styleUrls: ['./resource-drawer.component.scss'],
})
export class ResourceDrawerComponent implements OnInit, AfterViewInit {
    @ViewChild(TreeListComponent) resourcesList!: TreeListComponent;
    @Input() mode: 'new'|'edit';
    @Input() resourceType: ResourceType;
    @Input() allocableDevices: Resource[];
    @Input() ownedDevices: Resource[];    
    @Input() departments: Resource[];
    @Input() resource: Resource;

    private _subscriptions: Subscription[] = [];
    columns: TsTreeListColumn[] = [];
    form: FormGroup;
    selectedDevicesIds: number[] = [];
    selectDepartments: Department[];
    selectedToggled: boolean = false;
    ResourceType = ResourceType; // Make the enum accessible in the template

    constructor(
        private readonly _translate: TranslateService,
        private readonly _font: FontService,
        private readonly _fb: FormBuilder,
        private _resourcesService: ResourcesService,
        private readonly _notification: NotificationService,
    ) {
        this._font.addIcon(faPlusCircle, faTrash);
        this.form = this._fb.group({
            code: [null, [Validators.required]],
            description: [null, [Validators.required]],
            department: [null, [Validators.required]],
            devices: [null, [Validators.minLength(0)]]
        });
     }

    async ngOnInit(): Promise<void> {
        const translationSubscription =
            this._translate.stream([
                'orders-list.status.draft',
                'orders-list.status.planned',
                'resources.device',                
            ]).subscribe((translations) => {
                this.columns = [
                    { // id
                        caption: 'id',
                        dataField: 'id',
                        showColumn: false,
                        allowFiltering: false,
                        allowEditing: false,
                        allowSorting: false,    
                        width: 80,                
                    },
                    {  // description
                        headerPlaceholder: translations['orders-list.search'],
                        dataField: 'description',
                        caption: translations['resources.device'],
                        allowEditing: false,
                        allowSorting: false,                    
                        dataType: 'string',
                    }
                ]               
            });
        this._subscriptions.push(translationSubscription);

        if(this.resource.resource == ResourceType.department){
            this.form = this._fb.group({
                code: [null, [Validators.required]],
                description: [null, [Validators.required]],
                devices: [null, [Validators.minLength(0)]]
            });            
        } else {
            this.form = this._fb.group({
                code: [null, [Validators.required]],
                description: [null, [Validators.required]],
                department: [null, [Validators.required]],
                devices: [null, [Validators.minLength(0)]]
            });            
        }

        this.allocableDevices = JSON.parse(JSON.stringify(this.allocableDevices.map(dev => {dev.selected = false; return dev})));        
        this.ownedDevices = JSON.parse(JSON.stringify(this.ownedDevices.map(dev => {dev.selected = true; return dev})));        
   
        if(this.mode == 'edit'){
            this.allocableDevices = [...this.allocableDevices, ...this.ownedDevices];
            this.selectedDevicesIds = this.ownedDevices.map(d => d.id);
            this.form.controls['code'].setValue(this.resource.code);
            this.form.controls['description'].setValue(this.resource.description);
            if(this.resource.resource == ResourceType.workCenter){
                const dep = this.departmentsList().find(dep => dep.id == this.resource.parentId);
                this.form.controls['department'].setValue({id: dep.id, val: dep.val});                
            }                  
        }

        if(this.resource.resource == ResourceType.workCenter)
            this.selectDepartments = this.departmentsList();

        this.allocableDevices.sort((d1,d2) => {return d1.description >= d2.description ? 1 : -1})        
    }

    async ngAfterViewInit(): Promise<void> {
        // Load data

    }

    async ngAfterContentChecked(): Promise<void> {
        if(!this.selectedToggled && this.resourcesList){
            setTimeout(() => {
                this.resourcesList.selectRows(this.ownedDevices.map(d=>d.id),true);                
            }, 0);
            this.selectedToggled = true;
        }        
    }

    onSelectionChange(){
        const selectedRows = [...this.resourcesList.getSelectedRowData().map(e=>e['id'])];
        this.selectedDevicesIds = [...selectedRows];
    }

    ngOnDestroy(): void {
        // Unsubscribe all subscriptions to avoid memory leaks
        this._subscriptions.forEach((subscription: Subscription, index: number, array: Subscription[]) => {
            subscription.unsubscribe();
            array.splice(index, 1);
        });
    }

    get formValid(): boolean {
        return this.form.valid;
    }

    get f() {
        return this.form.controls;
    }

    departmentsList() {
        return this.departments.map(dep =>  {return {id: dep.id, val: dep.description}})
    }

    reset(type: ResourceType, mode: string, ownedDevice?: Resource[], id?: number) {
        this.form.reset();

    }

    async saveNewResource (plantId: number) {
        try {
            if(this.resource.resource == ResourceType.workCenter){
                if (this.form.valid){
                    const code = this.form.get('code')?.value;
                    const description = this.form.get('description')?.value;
                    const departmentId = this.form.get('department')?.value?.id;
    
                    const result = await this._resourcesService.newWorkCenter(
                        plantId, code, description, departmentId, this.selectedDevicesIds.length ? this.selectedDevicesIds : null
                    );
                    
                    return result;
                }
            } else if (this.resource.resource == ResourceType.department) {
                if (this.form.valid){
                    const code = this.form.get('code')?.value;
                    const description = this.form.get('description')?.value;
                    
                    const result = await this._resourcesService.newDepartment(
                        plantId, code, description , this.selectedDevicesIds.length ? this.selectedDevicesIds : null
                    );    
                 
                    return result;
                }
            }            
        } catch (error) {
            return false;
        }

    }

    async editResource () {
        try {
            if(this.resource.resource == ResourceType.workCenter){
                if (this.form.valid){
                    const code = this.form.get('code')?.value;
                    const description = this.form.get('description')?.value;
                    const department = this.form.get('department')?.value;
                    const result = await this._resourcesService.editWorkCenter(
                        this.resource.id,department.id,  code, description, this.selectedDevicesIds.length ? this.selectedDevicesIds : null
                    );      
                    
                    return result;
                }
            } else if (this.resource.resource == ResourceType.department) {
                if (this.form.valid){
                    const code = this.form.get('code')?.value;
                    const description = this.form.get('description')?.value;
                    const result = await this._resourcesService.editDepartment(
                         this.resource.id, code, description, this.selectedDevicesIds.length ? this.selectedDevicesIds : null
                    ); 
                    return result;
                }
            }
        } catch (error) {
            return false;
        }
    }
}
