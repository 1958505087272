<div class="process-data-page">
    <mat-form-field class="machine-select">
        <mat-label>{{'devices.single' | translate}}</mat-label>
        <mat-select [(ngModel)]="selectedDevice" (ngModelChange)="deviceChanged()">
            <mat-option *ngFor="let device of devices" [value]="device">{{device.label}}
                ({{ device.Company ? device.Company.name : '?' }})</mat-option>
        </mat-select>
    </mat-form-field>

    <ngx-datatable #processDataTable
        [rows]="tmpProcessData"
        [columns]="columns"
        [columnMode]="'force'"
        [headerHeight]="50"
        [rowHeight]="120"
        [scrollbarV]="true"
        class="data-table">
    </ngx-datatable>

</div>

<ng-template #addItem ngx-datatable-header-template>
    <div class="add-new-element-button" (click)="addProcessData()"
        [ngClass]="{'disabled': editRow || editRow === 0}">
        <i class="fa fa-plus"></i>
        <span>{{'processData.addNew' | translate}}</span>
    </div>
</ng-template>

<ng-template #code let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <span *ngIf="!isEditRow(rowIndex)" class="text-cell">{{row.code}}</span>
        <mat-form-field *ngIf="isEditRow(rowIndex)">
            <input matInput type="text" [(ngModel)]="row.code" (ngModelChange)="setModified(rowIndex)">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #description let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <span *ngIf="!isEditRow(rowIndex)" class="text-cell">{{row.description}}</span>
        <mat-form-field *ngIf="isEditRow(rowIndex)">
            <input matInput type="text" [(ngModel)]="row.description" (ngModelChange)="setModified(rowIndex)">
        </mat-form-field>
    </div>
</ng-template>


<ng-template #valueType let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <div *ngIf="!isEditRow(rowIndex)">
            <span class="text-cell">{{row.valueType}}</span>
        </div>
        <mat-select placeholder="ValueType" *ngIf="isEditRow(rowIndex)" [(ngModel)]="row.valueType"
            floatPlaceholder="never" (ngModelChange)="setModified(rowIndex)">
            <mat-option *ngFor="let type of valueTypes" [value]="type.value">{{type.name}}</mat-option>
        </mat-select>
    </div>
</ng-template>

<ng-template #actions let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="buttons-container" [ngClass]="{'disabled': isEditMode(rowIndex)}">
        <button mat-mini-fab (click)="back(rowIndex)" *ngIf="isEditRow(rowIndex)">
            <mat-icon class="fa fa-chevron-left"></mat-icon>
        </button>
        <button mat-mini-fab class="red-button" (click)="deleteProcessData(row, rowIndex)" *ngIf="isEditRow(rowIndex)">
            <mat-icon class="fa fa-trash-o"></mat-icon>
        </button>
        <button mat-mini-fab class="green-button" (click)="saveProcessData(row, rowIndex)" *ngIf="isEditRow(rowIndex)"
            [disabled]="!isModified() || !row.code || row.code.length <= 0 || !row.description || row.description.length <= 0">
            <mat-icon class="fa fa-floppy-o"></mat-icon>
        </button>
        <button mat-mini-fab *ngIf="!isEditRow(rowIndex)" (click)="edit(rowIndex)">
            <mat-icon class="fa fa-pencil"></mat-icon>
        </button>
    </div>
</ng-template>