<div class="units-page">
    <div class="content">
        <div class="header">
            <div class="column">
                <h2>{{'productionUnits.title' | translate}}</h2>
            </div>
        </div>

        <div class="body">
            <div class="row">
                <div class="column"></div>
                <div class="column">
                    <div class="button-group end">
                        <ts-button type="primary medium blue" text="{{'productionUnits.actions.new' | translate}}"
                                   (click)="onNewClicked()"></ts-button>
                    </div>
                </div>
            </div>
            <div class="tableWrp">    
                <ts-tree-list #unitsList [data]="units" [columns]="columns" key="id" parentKey="parentId"
                            actionsColumnCaption="" groupedActionsTooltipText=""
                            emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}" emptyStateSecondaryText=""
                            emptyStateSecondaryLink="" [enablePaging]="false" [showSearchBar]="false"
                            [hideActionsColumn]="true" [showRowLines]="true" [showBorders]="false" [allowRowDeleting]="false"
                            [allowRowInserting]="false" [allowColumnReordering]="false" [allowRowUpdating]="false"
                            [wordWrapEnabled]="true">
                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data>
                        <units-list-cell [tplKey]="tplKey" [data]="data" (editUnitClicked)="onEditUnitClicked($event)"
                                        (deleteUnitClicked)="onDeleteUnitClicked($event)">
        
                        </units-list-cell>
                    </ng-template>
                </ts-tree-list>
            </div>
        </div>
    </div>
</div>