<div class="categories-page">
    <mat-form-field class="machine-select" *ngIf="isAdmin">
        <mat-select placeholder="{{'devices.columns.company' | translate}}" class="machine-select"
            [(ngModel)]="selectedCompany" (ngModelChange)="companyChanged()">
            <mat-option *ngFor="let company of companies" [value]="company">{{company.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="tables">
        <div class="planned-columns">
            <h3 class="title">{{ 'categories.planned' | translate }}</h3>
            <ngx-datatable [rows]="tmpPlannedList" [columns]="plannedColumns" [columnMode]="'force'" [headerHeight]="50"
                [rowHeight]="60" [scrollbarV]="true" class="data-table">
            </ngx-datatable>
        </div>

        <hr class="separator" width="1" size="500">

        <div class="unPlanned-columns">
            <h3 class="title">{{ 'categories.unplanned' | translate }}</h3>
            <ngx-datatable [rows]="tmpUnPlannedList" [columns]="unPlannedColumns" [columnMode]="'force'"
                [headerHeight]="50" [rowHeight]="60" [scrollbarV]="true" class="data-table">
            </ngx-datatable>
        </div>
    </div>
</div>

<ng-template #addItemPlanned ngx-datatable-header-template>
    <div class="add-new-element-button" (click)="add(1)" [ngClass]="{'disabled': editRowPlanned || editRow === 0}">
        <i class="fa fa-plus"></i>
        <span>{{'categories.add' | translate}}</span>
    </div>
</ng-template>

<ng-template #addItemUnplanned ngx-datatable-header-template>
    <div class="add-new-element-button" (click)="add(0)" [ngClass]="{'disabled': editRowUnplanned || editRow === 0}">
        <i class="fa fa-plus"></i>
        <span>{{'categories.add' | translate}}</span>
    </div>
</ng-template>

<ng-template #namePlanned let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="name-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRowPlanned !== rowIndex}">
        <span *ngIf="editRowPlanned !== rowIndex" class="tag-name">{{row.name}}</span>
        <mat-form-field *ngIf="editRowPlanned === rowIndex">
            <input matInput type="text" [(ngModel)]="row.name" (ngModelChange)="setModified()">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #nameUnplanned let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="name-container"
        [ngClass]="{'disabled': (editRow || editRowUnplanned === 0) && editRowUnplanned !== rowIndex}">
        <span *ngIf="editRowUnplanned !== rowIndex" class="tag-name">{{row.name}}</span>
        <mat-form-field *ngIf="editRowUnplanned === rowIndex">
            <input matInput type="text" [(ngModel)]="row.name" (ngModelChange)="setModified()">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #actionsPlanned let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="buttons-container"
        [ngClass]="{'disabled': (editRowPlanned || editRowPlanned=== 0) && editRowPlanned !== rowIndex}">
        <button mat-mini-fab (click)="back(1, rowIndex)" *ngIf="editRowPlanned === rowIndex">
            <mat-icon class="fa fa-chevron-left"></mat-icon>
        </button>
        <button mat-mini-fab class="red-button" (click)="delete(row, rowIndex)" *ngIf="editRowPlanned === rowIndex">
            <mat-icon class="fa fa-trash-o"></mat-icon>
        </button>
        <button mat-mini-fab class="green-button" (click)="save(row, rowIndex)" *ngIf="editRowPlanned === rowIndex"
            [disabled]="!modifiedPlanned || row.name.length === 0">
            <mat-icon class="fa fa-floppy-o"></mat-icon>
        </button>
        <button mat-mini-fab *ngIf="editRowPlanned !== rowIndex" (click)="setModified(1, rowIndex)"
            [disabled]="!row.companyId">
            <mat-icon class="fa fa-pencil"></mat-icon>
        </button>
    </div>
</ng-template>

<ng-template #actionsUnplanned let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="buttons-container"
        [ngClass]="{'disabled': (editRowUnplanned || editRowUnplanned === 0) && editRowUnplanned !== rowIndex}">
        <button mat-mini-fab (click)="back(0, rowIndex)" *ngIf="editRowUnplanned === rowIndex">
            <mat-icon class="fa fa-chevron-left"></mat-icon>
        </button>
        <button mat-mini-fab class="red-button" (click)="delete(row, rowIndex)" *ngIf="editRowUnplanned === rowIndex">
            <mat-icon class="fa fa-trash-o"></mat-icon>
        </button>
        <button mat-mini-fab class="green-button" (click)="save(row, rowIndex)" *ngIf="editRowUnplanned === rowIndex"
            [disabled]="!modifiedUnplanned || row.name.length === 0">
            <mat-icon class="fa fa-floppy-o"></mat-icon>
        </button>
        <button mat-mini-fab *ngIf="editRowUnplanned !== rowIndex" (click)="setModified(0, rowIndex)"
            [disabled]="!row.companyId">
            <mat-icon class="fa fa-pencil"></mat-icon>
        </button>
    </div>
</ng-template>