<div class="devices-dialog">

    <h1 mat-dialog-title>{{'dialogs.devices.title' | translate}}</h1>
    <div mat-dialog-content>
        <p *ngIf="_sidebar.selected === 'stop_reasons'">{{'dialogs.devices.messageError' | translate}}</p>
        <p *ngIf="_sidebar.selected === 'products'">{{'dialogs.devices.messageProduct' | translate}}</p>
        <div class="devices">
            <ngx-datatable
                [rows]="devices"
                [columns]="columns"
                [selectionType]="'multiClick'"
                [columnMode]="'force'"
                [headerHeight]="51"
                [rowHeight]="70"
                [selected]="selected"
                [rowIdentity]="rowIdentityComparator"
                [scrollbarV]="true"
                class="data-table">
            </ngx-datatable>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button class="button-red" (click)="onNoClick()" tabindex="-1">{{'dialogs.devices.cancel' | translate}}</button>
        <button mat-raised-button class="button-green button-confirm" (click)="confirm()" tabindex="2" [disabled]="selected.length === 0">{{'dialogs.devices.confirm' | translate}}</button>
    </div>
    
    <ng-template #checkboxHeader ngx-datatable-header-template>
        <div (click)="selectAll()">
            <mat-icon class="checkbox-icon fa" [ngClass]=" { 'fa-square-o': !isSelectedAll(), 'fa-check-square-o': isSelectedAll() }"></mat-icon>
            <span>{{'dialogs.devices.select-all' | translate}}</span>
        </div>
    </ng-template>
    
    <ng-template #checkbox let-row="row" ngx-datatable-cell-template>
        <mat-icon class="checkbox-icon fa" [ngClass]=" { 'fa-square-o': !isSelected(row.id), 'fa-check-square-o': isSelected(row.id) }"></mat-icon>
    </ng-template>
</div>
