<div class="work-page">
    <div class="content">
        <div class="header">
            <div class="top-row">
                <span>{{'products.works.title' | translate}}</span>
                <div class="arrow-container">
                    <div class="arrow"></div>
                </div>
                <span *ngIf="!editMode">{{'products.works.createEdit.createTitle' | translate}}</span>
                <span *ngIf="editMode">{{'products.works.createEdit.editTitle' | translate}}</span>
            </div>
            <div class="bottom-row">
                <div class="bottom-arrow-wrp">
                    <ts-button [type]="'primary small white'" icon="arrow-left"
                               (buttonClicked)="handleCancelClicked()"></ts-button>
                </div>
                <div class="submenu-label-wrp">
                    <span *ngIf="editMode">{{'products.works.createEdit.editTitle' | translate}}</span>
                    <span *ngIf="!editMode">{{'products.works.createEdit.createTitle' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="content-wrp">
            <form [formGroup]="form">
                <div class="section">
                    <span class="title">{{'products.works.columns.work' | translate}}</span>


                    <div class="input-row">
                        <ts-text-box primaryText="{{'products.works.columns.code' | translate}} *"
                                     placeholderText="{{'products.works.columns.code' | translate}}"
                                     [validationDict]="customValidationDict | translateJSON | async"
                                     formControlName="code" [enabled]="!editMode" [showErrors]="true"></ts-text-box>
                    </div>
                    <div class="input-row">
                        <vapor-angular-text-area class=" text-area"
                                                 primaryText="{{'products.works.columns.description' | translate}} *"
                                                 placeholderText="{{'products.works.columns.description' | translate}}"
                                                 formControlName="description" [minLength]="1" [maxLength]="100"
                                                 [validationDict]="customValidationDict | translateJSON | async"
                                                 [showErrors]="true"></vapor-angular-text-area>
                    </div>
                    <div class="input-row">
                        <ts-select class="select" [data]="managedSelectData"
                        primaryText="{{'products.works.managed' | translate}} *"
                        formControlName="managed"></ts-select>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="footer">
        <div>
            <ts-button [type]="'secondary medium blue'" (buttonClicked)="handleCancelClicked()"
                       text="{{'products.works.createEdit.cancelButton' | translate}}">
            </ts-button>
            <ts-button [type]="'primary medium blue'" (buttonClicked)="handleSaveClicked()"
                       text="{{'products.works.createEdit.saveButton' | translate}}">
            </ts-button>
        </div>
    </div>
</div>