<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
    <p>{{data.message}}</p>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onClose('')" tabindex="-1">{{'dialogs.delete.cancel' | translate}}</button>
    <button mat-raised-button (click)="onClose('disable')" tabindex="2" *ngIf="!data.disabled || (data.disabled && data.disabled === 'yes')">
        {{'dialogs.delete.disable' | translate}}
    </button>
    <button mat-raised-button (click)="onClose('delete')" tabindex="2">{{'dialogs.delete.confirm' | translate}}</button>
</div>