<div class="qr-dialog">
    <h1 mat-dialog-title>{{data.label}}</h1>
    <div mat-dialog-content>
        <qr-code id="qrImage" [value]=data.serial [size]="300"></qr-code>
        <p>{{'devices.columns.serial' | translate}}: {{data.serial}}</p>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button (click)="onClose('print')" tabindex="2">{{'dialogs.qr.print' | translate}}</button>
        <button mat-raised-button (click)="onClose('')" tabindex="-1">{{'dialogs.qr.close' | translate}}</button>
    </div>
</div>