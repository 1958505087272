<!-- <mat-toolbar *ngIf="_auth.authenticated">
        <div class="side left">
            <i *ngIf="!isLoginPage()" class="icon icon-menu" (click)="toggleMenu()"></i>
        </div>
    <a class="toolbar-link" href="/">
        <img src="./assets/images/logo.png">
    </a>

    <span class="filler">{{navbar.title}}</span>

    <span class="mail" *ngIf="_auth.user">{{_auth.user.email}}</span>
    <button mat-icon-button (click)="logout()">
        <i class="fa fa-sign-out"></i>
    </button>
    <img class="lang-icon" src="./assets/images/lang/{{currentLang}}.svg" (click)="openMenu = !openMenu">

    <div class="lang-dropdown" [ngClass]="{ 'show': openMenu }">
        <ng-template ngFor let-lang [ngForOf]="languages">
            <div class="lang-button" (click)="setLanguage(lang.code)">
                <img class="lang-icon" src="./assets/images/lang/{{lang.code}}.svg">
            </div>
        </ng-template>
    </div>
</mat-toolbar> -->

<div class="navbar" *ngIf="!isLoginPage()">
  <div class="side left">
    <i *ngIf="!isChildPage" class="icon icon-menu" (click)="toggleMenu()"></i>
    <h2 *ngIf="!isChildPage" class="title" (click)="toggleMenu()">{{navbar.title}}</h2>
    <i *ngIf="isChildPage" style="cursor:pointer;" class="icon icon-left-arrow" (click)="goBack()"></i>
    <h2 *ngIf="isChildPage" class="title">{{navbar.title}}</h2> 
  </div>
  <img class="logo" [src]="'./assets/images/team_system/logo.png'">
  <div class="side right">
    <div *ngIf="isAdmin && !!company" class="companiesFormWrapper" style="background-color: aliceblue;">
      <form class="companiesForm">
        <mat-form-field>
           <input 
              type="text" 
              placeholder="Filter Company" 
              [formControl]="companiesControl"
              matInput
              [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCompanySelected($event)">
                <mat-option 
                  [value] = "company"
                  *ngFor="let company of filteredCompanies | async" 
                  selected="true"
                  >
                  {{company}}
               </mat-option>
           </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>   

    <div *ngIf="_auth.user">
      <p *ngIf="_auth.user.lastName">{{_auth.user.lastName + ' ' + _auth.user.firstName}}</p>
      <p *ngIf="_auth.user.email && !_auth.user.lastName">{{_auth.user.email}}</p>
    </div>
    <i *ngIf="!isLoginPage()" class="icon icon-logout" (click)="logout()"></i>
    <img class="lang-icon" src="./assets/images/lang/{{currentLang}}.svg" (click)="openMenu = !openMenu">

    <div class="lang-dropdown" [ngClass]="{ 'show': openMenu }">
      <ng-template ngFor let-lang [ngForOf]="languages">
        <div class="lang-button" (click)="setLanguage(lang.code)">
          <img class="lang-icon" src="./assets/images/lang/{{lang.code}}.svg">
        </div>
      </ng-template>
    </div>
  </div>
  <div class="side-menu" [ngClass]="{'active' : showMenu}">
    <div class="content">
      <div class="left">
        <div class="menu-top" (click)="toggleMenu()">
          <h2 class="title">MENU</h2>
          <i class="icon icon-left-arrow"></i>
        </div>
        <nav>
          <h3 *ngIf="isOrdersManager">{{'orders-list.title' | translate}}</h3>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'orders-list' }" (click)="toggleMenu()">
            <li routerLink="/orders-list">{{'orders-list.subtitle' | translate}}</li>
          </ul>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'works' }" (click)="toggleMenu()">
            <li routerLink="/works">{{'products.works.title' | translate}}</li>
          </ul>
         
          <h3>{{'stopReasons.stops' | translate}}</h3>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'stop_reasons' }" (click)="toggleMenu()">
            <li routerLink="/stops">{{'stopReasons.title' | translate}}</li>
          </ul>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'tags' }" (click)="toggleMenu()">
            <li routerLink="/tags">{{'tags.title' | translate}}</li>
          </ul>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'categories' }" (click)="toggleMenu()">
            <li routerLink="/categories">{{'categories.title' | translate}}</li>
          </ul>
         
          <h3>{{'products.title' | translate}}</h3>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'products' }" (click)="toggleMenu()">
            <li routerLink="/products">{{'products.title' | translate}}</li>
          </ul>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'units' }" (click)="toggleMenu()">
            <li routerLink="/units">{{'productionUnits.title' | translate}}</li>
          </ul>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'families' }" (click)="toggleMenu()">
            <li routerLink="/families">{{'productsFamily.title' | translate}}</li>
          </ul>

          <h3>{{'devices.title' | translate}}</h3>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'devices' }" (click)="toggleMenu()">
            <li routerLink="/devices">{{'devices.active' | translate}}</li>
          </ul>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'resources' }" (click)="toggleMenu()">
            <li routerLink="/resources">{{'devices.resources' | translate}}</li>
          </ul>
          <ul *ngIf="isAdmin" [ngClass]="{ 'selected': sidebar.selected === 'new-device' }" (click)="toggleMenu()">
            <li routerLink="/devices/new">{{'devices.uninitialized' | translate}}</li>
          </ul>
          <ul *ngIf="isAdmin || isManager" [ngClass]="{ 'selected': sidebar.selected === 'process-data' }" (click)="toggleMenu()">
            <li routerLink="/process-data">{{'devices.processdata' | translate}}</li>
          </ul>
          <ul *ngIf="isAdmin" [ngClass]="{ 'selected': sidebar.selected === 'plugin' }" (click)="toggleMenu()">
            <li routerLink="/plugin">{{'devices.plugin' | translate}}</li>
          </ul>

          <h3>{{'schedules.schedules' | translate}}</h3>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'shifts' }" (click)="toggleMenu()">
            <li routerLink="/shifts">{{'work-shifts.title' | translate}}</li>
          </ul>
          <h3>{{'quality-controls.title' | translate }}</h3>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'quality-controls' }" (click)="toggleMenu()">
            <li routerLink="/quality-controls">{{'quality-controls.title' | translate }}</li>
          </ul>
          
          <h3>{{'users.title' | translate }}</h3>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'users' }" (click)="toggleMenu()">
            <li routerLink="/users">{{'users.title' | translate }}</li>
          </ul>
          <ul [ngClass]="{ 'selected': sidebar.selected === 'operators' }" (click)="toggleMenu()">
            <li routerLink="/operators">{{'operators.title' | translate }}</li>
          </ul>

          <h3 *ngIf="isAdmin">{{'login.admin-panel' | translate}}</h3>
          <ul *ngIf="isAdmin" [ngClass]="{ 'selected': sidebar.selected === 'companies' }" (click)="toggleMenu()">
            <li routerLink="/companies">{{'companies.title' | translate}}</li>
          </ul>
        </nav>
        <!-- <div class="menu-item" (click)="toggleMenu(); logout()">
                        <p>logout</p>
                        <i class="icon icon-logout"></i>
                    </div> -->
      </div>
      <div class="right" (click)="toggleMenu()"></div>
    </div>

  </div>
</div>

<div class="navbar-login" *ngIf="isLoginPage()">
  <img class="logo" [src]="'./assets/images/team_system/logo_login.png'">
  <div class="side left"></div>
  <div class="side right">
    <i *ngIf="!isLoginPage()" class="icon icon-logout" (click)="logout()"></i>
    <img class="lang-icon" src="./assets/images/lang/{{currentLang}}.svg" (click)="openMenu = !openMenu">
    <div class="lang-dropdown" [ngClass]="{ 'show': openMenu }">
      <ng-template ngFor let-lang [ngForOf]="languages">
        <div class="lang-button" (click)="setLanguage(lang.code)">
          <img class="lang-icon" src="./assets/images/lang/{{lang.code}}.svg">
        </div>
      </ng-template>
    </div>
  </div>
</div>
