<div class="works-page">
    <div class="content">
        <div class="header">
            <div class="header-raw">
                <span>{{'products.works.title' | translate}}</span>                               
            </div>
        </div>
        <div class="tableWrp">
            <div class="ts-vapor ts-layout layout-container">
                <div class="new-article-row">
                    <div class="column">
                        <div class="button-group end">
                            <ts-button
                                #addArticleButton
                                text="{{'products.works.new' | translate}}"
                                type="primary medium blue"
                                (buttonClicked)="this.onNewWorkClick()">
                            </ts-button>    
                        </div>
                    </div>
                </div>
                <ts-tree-list #worksList [data]="works" [columns]="worksColumns" key="id"
                                parentKey="groupId" actionsColumnCaption="" groupedActionsTooltipText=""
                                emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                                emptyStateSecondaryText="" emptyStateSecondaryLink="" [enablePaging]="false"
                                [showSearchBar]="false" [hideActionsColumn]="true" [showRowLines]="true"
                                [showBorders]="false" [allowRowDeleting]="false" [allowRowInserting]="false"
                                [allowRowUpdating]="false" [wordWrapEnabled]="true" [height]="worksListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>
                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
                        <app-works-list-cell #actionsProductTooltip [tplKey]="tplKey" [data]="data"
                            (editWorkClicked)="onEditWorkClicked(data)"
                            (deleteWorkClicked)="onDeleteWorkClicked(data)"
                        ></app-works-list-cell>                    
                    </ng-template>
                </ts-tree-list>
            </div>
        </div>
    </div>
</div>

<app-generic-dialog *ngIf="showModal"  [data]="modalData" (primaryButtonClicked)="onDeleteModalClicked()" (cancelButtonClicked)="onCancelModalClicked()"></app-generic-dialog>