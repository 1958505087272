<div class="devices-page">

    <div class="filters">
		<mat-form-field>
			<input matInput type="text" [(ngModel)]="codeSearch" (ngModelChange)="!isAdmin ? filter(): filterAdmin()"
				placeholder="{{!isAdmin ? ('devices.filterDevice' | translate) : ('devices.filterDeviceAdmin' | translate)}}">
		</mat-form-field>
		<button mat-mini-fab (click)="filterReset()">
			<i class="fa fa-times"></i>
		</button>
	</div>

    <ngx-datatable
    [rows]="tmpDevices"
    [columns]="columns"
    [columnMode]="'force'"
    [headerHeight]="70"
    [rowHeight]="60"
    [scrollbarV]="true"
    class="data-table">
</ngx-datatable>

<!-- <a href="/devices/new">
    <div class="add-new-element-button">
        <i class="fa fa-plus"></i>
        <span i18n>Add new device</span>
    </div>
</a> -->
</div>

<ng-template #label let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <span *ngIf="editRow !== rowIndex" class="device-label">{{row.label}}</span>
        <mat-form-field *ngIf="editRow === rowIndex">
            <input matInput type="label" [(ngModel)]="row.label" (ngModelChange)="setModified()">
        </mat-form-field>
    </div>
</ng-template>

<ng-template #stopThreshold let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <span *ngIf="editRow !== rowIndex" class="device-label">{{ getStopTreshold(row) }} <span>{{'units.sec' | translate}}</span></span>
        <mat-form-field class="stop-threshold" *ngIf="editRow === rowIndex">
            <input matInput type="number" [(ngModel)]="row.stopProductsThresholdSec" (ngModelChange)="setModified()">
            <label>{{'units.sec' | translate}}</label>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #startThreshold let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <span *ngIf="editRow !== rowIndex" class="device-label">{{ getStartTreshold(row) }} <span>{{'units.pieces' | translate}}</span></span>
        <mat-form-field class="start-threshold" *ngIf="editRow === rowIndex">
            <input matInput type="number" [(ngModel)]="row.resumeProductionProductsThreshold" (ngModelChange)="setModified()">
            <label>{{'units.pieces' | translate}}</label>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #multiplier let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <span *ngIf="editRow !== rowIndex" class="device-label">{{ getMultiplier(row) }} <span>{{'units.pieces' | translate}}</span></span>
        <mat-form-field class="start-threshold" *ngIf="editRow === rowIndex">
            <input matInput type="number" [(ngModel)]="row.multiplier" (ngModelChange)="setModified()">
            <label>{{'units.pieces' | translate}}</label>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #scrapsMultiplier let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <span *ngIf="editRow !== rowIndex" class="device-label">{{ getScrapMultiplier(row) }} <span>{{'units.pieces' | translate}}</span></span>
        <mat-form-field class="start-threshold" *ngIf="editRow === rowIndex">
            <input matInput type="number" [(ngModel)]="row.scrapsMultiplier" (ngModelChange)="setModified()">
            <label>{{'units.pieces' | translate}}</label>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #enabled let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
    <div class="input-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <mat-slide-toggle [(ngModel)]="row.enabled" [disabled]="editRow !== rowIndex" (ngModelChange)="setModified()"></mat-slide-toggle>
    </div>
</ng-template>


<ng-template #actions let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
    <div class="buttons-container" [ngClass]="{'disabled': (editRow || editRow === 0) && editRow !== rowIndex}">
        <button mat-mini-fab (click)="back(rowIndex)" *ngIf="editRow === rowIndex">
            <mat-icon class="fa fa-chevron-left"></mat-icon>
        </button>
        <button mat-mini-fab class="green-button" (click)="save(row, rowIndex)" *ngIf="editRow === rowIndex"
            [disabled]="!modified || row.label.length === 0">
            <mat-icon class="fa fa-floppy-o"></mat-icon>
        </button>
        <button mat-mini-fab *ngIf="editRow !== rowIndex" (click)="showQr(row, rowIndex)">
            <mat-icon class="fa fa-qrcode"></mat-icon>
        </button>
        <button mat-mini-fab *ngIf="editRow !== rowIndex" (click)="edit(rowIndex)">
            <mat-icon class="fa fa-pencil"></mat-icon>
        </button>
    </div>
</ng-template>
