import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { UserInstance } from '../models/user.model';


@Injectable()
export class UserService {
    users: UserInstance[];


    constructor(private _http: HttpService, private _config: ConfigService) { }

    async getUsers(companyId?: number) {
        let url = `${this._config.API.url}/users?sort=desc`;

        if (companyId) {
            url += `&companyId=` + companyId;
        }

        const response = await this._http.get<UserInstance[]>(url);

        this.users = response.body;
        return this.users;
    }

    async create(data: any, lang: string) {
        const url = `${this._config.API.url}/createUser`;

        try {
            data.lang = lang;
            const response = await this._http.post<UserInstance>(url, data);
            const res = response.body;
            return res;

        } catch (err) {
            console.error('createUser:', err);
            return null;
        }
    }

    async update(data: UserInstance) {
        const url = `${this._config.API.url}/updateUser/${data.id}`;
        try {

            const response = await this._http.put(url, data);
            return response.status;

        } catch (err) {
            console.error('updateUser:', err);
            return null;
        }
    }

    async delete(userId: number) {
        const url = `${this._config.API.url}/deleteUser/${userId}`;
        const response = await this._http.delete(url);

        return response.status;
    }

    async resetPassword(email: String, lang: String) {
        const url = `${this._config.API.url}/forgotPassword`;
        const data = {
            email: email,
            lang: lang
        };
        const response = await this._http.post(url, data);

        return response.status;
    }
}
