<div class="orders-list-page">
    <div class="orders-list-content">
        <div class="header">
            <div class="column">
                <h2>{{'orders-list.title' | translate}}</h2>
            </div>
            <div class="column">
                <div class="right">
                    <ng-container *ngIf="plants?.length > 1">
                        <ts-select [data]="plants" [value]="plantValue" [clearable]="false" (valueChange)="handlePlantChange($event, true)"></ts-select>
                    </ng-container>
                </div>
            </div>
        </div>

        <ts-tab-panel [panelTabs]="tabs" type="primary" barType="standard" size="small" [(selectedTab)]="selectedTabId"
                      [selectTabOnClick]="true" (selectedTabChange)="selectedTabChange($event)">
        </ts-tab-panel>

        <ts-layout *ngIf="selectedTabId === 2" mode="flex" tsFxLayout="row"
                   tsFxLayoutAlign="start start" gap="0px 8px" class="filters-layout">
            <ts-layout-item [ngClass]="{'item': plants?.length > 1}" *ngIf="selectedTabId === 2">
                <ts-datepicker mode="date" [showErrors]="true"
                               primaryText="{{'orders-list.filters.from-date' | translate}}"
                               placeholder="{{'orders-list.filters.placeholder' | translate}}" [value]="fromDate"
                               [maxDate]="toDate" (valueChange)="handleDateChange($event, 'from')"></ts-datepicker>
            </ts-layout-item>
            <ts-layout-item class="item" *ngIf="selectedTabId === 2">
                <ts-datepicker mode="date" [showErrors]="true"
                               primaryText="{{'orders-list.filters.to-date' | translate}}"
                               placeholder="{{'orders-list.filters.placeholder' | translate}}" [value]="toDate"
                               [minDate]="fromDate" (valueChange)="handleDateChange($event, 'to')"></ts-datepicker>
            </ts-layout-item>
        </ts-layout>
        <ng-container [ngSwitch]="selectedTabId">
            <div *ngSwitchCase="1" class="ts-vapor ts-layout layout-container">
                <div class="row">
                    <div class="column">
                        <div class="button-group start">
                            <ts-button icon="layer-group" title="{{'orders-list.actions.newGroup' | translate}}"
                                       type="tertiary medium blue" [disabled]="!newGroupEnabled"
                                       (click)="onNewGroupClicked()"></ts-button>
                            <ts-button icon="layer-plus" title="{{'orders-list.actions.addToGroup' | translate}}"
                                       type="tertiary medium blue" [disabled]="!addToGroupEnabled"
                                       (click)="onAddToGroupClicked()"></ts-button>
                            <ts-button icon="layer-minus" title="{{'orders-list.actions.detachFromGroup' | translate}}"
                                       type="tertiary medium blue" [disabled]="!detachFromGroupEnabled"
                                       (click)="onDetachFromGroupClicked()"></ts-button>
                        </div>
                    </div>
                    <div class="column">
                        <div class="button-group end">
                            <ts-button icon="sort-numeric-up-alt"
                                       title="{{'orders-list.actions.sort-date' | translate}}"
                                       type="secondary medium blue" (click)="onSortByDateClicked()"></ts-button>
                            <ts-button text="{{'orders-list.actions.new' | translate}}" icon="plus"
                                       [tsActionTooltip]="newOrderOptions"
									   [tsTooltipPosition]="'bottomRight'"
									   [showTooltip]="showNewOrderOptions"
                                       (buttonClicked)="this.showNewOrderOptions = !this.showNewOrderOptions"></ts-button>
                            <ng-template #newOrderOptions>
                                <div class="text-container">
                                    <ts-h5 class="text-container__margin" (mwlClick)="onNewOrderDevicesClicked()">
                                        {{'orders-list.newOrder.devices' | translate}}</ts-h5>
                                    <ts-h5 class="text-container__margin" (mwlClick)="onNewOrderWorkcyclesClicked()">
                                        {{'orders-list.newOrder.workcycles' | translate}}</ts-h5>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <ts-tree-list #plannedOrdersList [data]="plannedOrders" [columns]="plannedOrdersColumns" key="id"
                              parentKey="groupId" actionsColumnCaption="" groupedActionsTooltipText=""
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              emptyStateSecondaryText="" emptyStateSecondaryLink="" [enablePaging]="false"
                              [showSearchBar]="false" [hideActionsColumn]="true" [showRowLines]="true"
                              [showBorders]="false" [allowRowDeleting]="false" [allowRowInserting]="false"
                              [allowRowUpdating]="false" [wordWrapEnabled]="true" [height]="plannedOrdersListHeight">
                    <ts-tree-list-row-dragging [allowDropInsideItem]="false" [allowReordering]="true"
                                               [showDragIcons]="true" [onReorder]="onReorder"
                                               [onDragChange]="onDragChange"></ts-tree-list-row-dragging>
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>
                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
                        <app-orders-list-cell [tplKey]="tplKey" [data]="data" [level]="level"
                                              [selectedDevicesId]="plannedOrdersSelectedDevicesId"
                                              [selectionEnabled]="true" [editEnabled]="!newGroupEnabled"
                                              (deleteOrderClicked)="onDeleteOrderClicked($event)"
                                              (deleteOrdersGroupClicked)="onDeleteOrdersGroupClicked($event)"
                                              (orderInfoClicked)="onOrderInfoClicked(data)"
                                              (ordersGroupInfoClicked)="onOrdersGroupInfoClicked($event)"
                                              (editOrderClicked)="onEditOrderClicked($event)"
                                              (editOrdersGroupClicked)="onEditOrdersGroupClicked($event)"
                                              (rankChanged)="onRankChanged($event)"
                                              (rowSelected)="onRowSelected($event)"
                                              (rowUnselected)="onRowUnselected($event)"></app-orders-list-cell>
                    </ng-template>
                </ts-tree-list>
            </div>
            <div *ngSwitchCase="2" class="ts-vapor ts-layout layout-container">
                <ts-tree-list #productionOrdersList [data]="productionOrders" [columns]="productionOrdersColumns"
                              key="id" parentKey="groupId" actionsColumnCaption="" groupedActionsTooltipText=""
                              emptyStatePrimaryText="{{'common.emptyState.primaryText' | translate}}"
                              emptyStateSecondaryText="" emptyStateSecondaryLink="" [enablePaging]="false"
                              [showSearchBar]="false" [hideActionsColumn]="true" [showRowLines]="true"
                              [showBorders]="false" [allowRowDeleting]="false" [allowRowInserting]="false"
                              [allowRowUpdating]="false" [wordWrapEnabled]="true" [height]="productionOrdersListHeight">
                    <ts-tree-list-scrolling [mode]="'virtual'"></ts-tree-list-scrolling>
                    <ng-template #treeListRootTemplate let-tplKey="tplKey" let-data let-level="level">
                        <app-orders-list-cell [tplKey]="tplKey" [data]="data" [level]="level" [editEnabled]="false"
                                              [deleteEnabled]="false" (orderInfoClicked)="onOrderInfoClicked(data)"
                                              (ordersGroupInfoClicked)="onOrdersGroupInfoClicked($event)"></app-orders-list-cell>
                    </ng-template>
                </ts-tree-list>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #newOrderTemplate>
    <app-order-drawer #newOrder></app-order-drawer>
</ng-template>
<ng-template #newOrderBottomBarTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.create-button' | translate}}" [disableExit]="!newOrder?.formValid"
                   (closePage)="onCreateOrderClicked()">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>

<ng-template #newOrdersGroupTemplate>
    <app-orders-group-editor #newOrdersGroup [selectedOrders]="plannedOrdersSelected"></app-orders-group-editor>
</ng-template>
<ng-template #newOrdersGroupBottomBarTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.create-button' | translate}}" [disableExit]="!newOrdersGroup?.formValid"
                   (closePage)="onCreateOrdersGroupDrawerClicked()">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>

<ng-template #orderInfoTemplate>
    <app-order-info #orderInfo [order]="selectedOrder" [toBeProduced]="selectedTabId" [companyId]="selectedCompanyId"></app-order-info>
</ng-template>
<ng-template #orderInfoBottomBarTemplate>
    <ts-layout class="orderRowLastUpdate">
        <ts-layout-item *ngIf="!!selectedOrder?.manualCreation" class="orderInfoItem">
            {{ 'orders-list.columns.createdAndUpdatedAt' | translate:{ createdAt: parseDate(selectedOrder?.createdAt), updatedAt: parseDate(selectedOrder?.updatedAt) } }}
        </ts-layout-item>
        <ts-layout-item *ngIf="!selectedOrder?.manualCreation" class="orderInfoItem">
            {{ 'orders-list.columns.createdAndImportedAt' | translate:{ createdAt: parseDate(selectedOrder?.createdAt), updatedAt: parseDate(selectedOrder?.updatedAt) } }}
        </ts-layout-item>
    </ts-layout>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.orderInfo.editButton' | translate}}"
                   [disableExit]="orderInfoComponent?.submitDisabled" (closePage)="onEditOrderStatusClicked()">
        <ts-button type="tertiary blue medium" text="{{'orders-list.orderInfo.cancelButton' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>

<ng-template #orderGroupInfoTemplate>
    <app-order-group-info #orderGroupInfo [orderGroup]="selectedOrderGroup"
                          [toBeProduced]="selectedTabId"></app-order-group-info>
</ng-template>
<ng-template #orderGroupInfoBottomBarTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.orderInfo.editButton' | translate}}"
                   [disableExit]="orderGroupInfoComponent?.submitDisabled"
                   (closePage)="onEditOrderGroupStatusClicked()">
        <ts-button type="tertiary blue medium" text="{{'orders-list.orderInfo.cancelButton' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>

<ng-template #editOrderTemplate>
    <app-order-drawer #editOrder [order]="selectedOrder" isEditing="true"></app-order-drawer>
</ng-template>
<ng-template #editOrderBottomBarTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.save-button' | translate}}" [disableExit]="!editOrder?.formValid"
                   (closePage)="onEditOrderButtonClicked()">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>

<ng-template #editOrdersGroupTemplate>
    <app-orders-group-editor #editOrdersGroup [group]="selectedOrder" isEditing="true"></app-orders-group-editor>
</ng-template>
<ng-template #editOrdersGroupBottomBarTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.save-button' | translate}}" [disableExit]="!editOrdersGroup?.formValid"
                   (closePage)="onEditOrdersGroupDrawerClicked()">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>

<ng-template #addOrdersToGroupTemplate>
    <app-add-orders-to-group #addOrdersToGroup [groupsList]="plannedOrdersGroups"
                             [selectedOrders]="plannedOrdersSelected"></app-add-orders-to-group>
</ng-template>
<ng-template #addOrdersToGroupBottomBarTemplate>
    <ts-divider direction="horizontal" mainPaddingMult="0" sidePaddingMult="0"></ts-divider>
    <ts-bottom-bar exitLabel="{{'orders-list.add-button' | translate}}" [disableExit]="!addOrdersToGroup?.selectedGroup"
                   (closePage)="onAddOrdersToGroupDrawerClicked()">
        <ts-button type="tertiary blue medium" text="{{'orders-list.cancel-button' | translate}}"
                   (buttonClicked)="onCancelDrawerClicked()"></ts-button>
    </ts-bottom-bar>
</ng-template>