<ng-container *ngIf="selectionEnabled && tplKey === 'tplSelection'">
    <ts-simple-checkbox [enabled]="data?.checkBoxEnabled" [value]="false"
                        (valueChange)="onSelectionChanged(data, $event)"></ts-simple-checkbox>
</ng-container>
<span *ngIf="tplKey === 'tplDevices'">
    <ts-tree-list-cell *ngIf="data?.Devices?.length == 1" [cellText]="data?.Devices[0].label" [hyperlinkEnabled]="false"
                       [buttonIsVisible]="false"></ts-tree-list-cell>
    <ts-tree-list-cell *ngIf="data?.Devices?.length > 1"
                       cellText="{{ 'orders-list.cells.devices' | translate:{ first: data?.Devices[0].label, others: data?.Devices?.length - 1 } }}"
                       [hyperlinkEnabled]="false" [buttonIsVisible]="false"></ts-tree-list-cell>
</span>
<ng-container *ngIf="tplKey === 'tplStatus'">
    <div class="mic-tag" [ngClass]="mapStatusToClass[data?.status]">
        <span [title]="mapStatusTagTitle[data?.status]">{{ mapStatusTagTitle[data?.status] }}</span>
    </div>
</ng-container>
<span *ngIf="tplKey === 'tplCode'" class="codeContainer">
    <div class="codeValue">{{ data.code }}</div>
    <div *ngIf="data.isGroup" class="badge">{{data.Orders?.length}}</div>
</span>
<span *ngIf="tplKey === 'tplActions'">
    <ts-tree-list-button icon="window-maximize" title="{{'orders-list.actions.details' | translate}}"
                         [disabled]="!infoEnabled"
                         (click)="data?.isGroup ? onOrdersGroupInfoClicked(data) : onOrderInfoClicked(data)"></ts-tree-list-button>
    <ts-tree-list-button icon="pencil" title="{{'orders-list.actions.edit' | translate}}" [disabled]="!editEnabled"
                         (click)="data?.isGroup ? onEditOrdersGroupClicked(data) : onEditOrderClicked(data)"></ts-tree-list-button>
    <ts-tree-list-button icon="trash-alt" [isDanger]="true" title="{{'orders-list.actions.delete' | translate}}"
                         [disabled]="!deleteEnabled"
                         (click)="data?.isGroup ? deleteOrderGroupClick(data) : deleteOrderClick(data)"></ts-tree-list-button>
</span>
<div *ngIf="tplKey === 'tplRank'">
    <input type="text" value="{{data.rank}}" (change)="onRankChanged(data, $event)" class="rank-input" />
</div>