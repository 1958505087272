<div class="shift-dialog-page">
    <h1 mat-dialog-title>
        {{ 'work-shifts.dialog.title' | translate }}
    </h1>
    <h4>
            {{ 'work-shifts.dialog.warning' | translate }}
    </h4>
    <div class="mat-dialog-content">
        <form>
            <mat-select class="machine-select" [(ngModel)]="copyYear" [ngModelOptions]="{standalone: true}">
                <mat-option [value]="0" > {{ 'work-shifts.dialog.one-week' | translate }} </mat-option>
                <mat-option [value]="1" > {{ 'work-shifts.dialog.one-year' | translate }} </mat-option>
            </mat-select >
            <div mat-dialog-actions>
                <button class="calendar-btn" (click)="onClose('')" tabindex="-1" i18n>{{ 'work-shifts.dialog.back' | translate }}</button>
                <button class="calendar-btn" (click)="onClose('save')" tabindex="2" i18n>{{ 'work-shifts.dialog.continue' | translate }}</button>
            </div>
        </form>
    </div>
</div>