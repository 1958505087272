import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { UiService } from './ui.service';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpService {
    private defaultHeaders: HttpHeaders = new HttpHeaders();

    constructor(
        private http: HttpClient,
        private router: Router,
        private _ui: UiService
    ) {}

    setDefaultHeader(key: string, value: string): void {
        this.defaultHeaders = this.defaultHeaders.set(key, value);
    }

    post<T>(url: string, body?: any, headers?: HttpHeaders): Promise<HttpResponse<T>> {
        const options = { withCredentials: true, headers: this.mergeHeaders(headers), observe: 'response' as 'response' };
        return this.intercept<T>(this.http.post<T>(url, body, options)).toPromise();
    }

    get<T>(url: string, headers?: HttpHeaders): Promise<HttpResponse<T>> {
        const options = { withCredentials: true, headers: this.mergeHeaders(headers), observe: 'response' as 'response' };
        return this.intercept<T>(this.http.get<T>(url, options)).toPromise();
    }

    delete<T>(url: string, body?: any,headers?: HttpHeaders): Promise<HttpResponse<T>> {
        const options = { withCredentials: true, headers: this.mergeHeaders(headers), body , observe: 'response' as 'response' };
        return this.intercept<T>(this.http.delete<T>(url, options)).toPromise();
    }

    put<T>(url: string, body?: any, headers?: HttpHeaders): Promise<HttpResponse<T>> {
        const options = { withCredentials: true, headers: this.mergeHeaders(headers), observe: 'response' as 'response' };
        return this.intercept<T>(this.http.put<T>(url, body, options)).toPromise();
    }

    patch<T>(url: string, body?: any, headers?: HttpHeaders): Promise<HttpResponse<T>> {
        const options = { withCredentials: true, headers: this.mergeHeaders(headers), observe: 'response' as 'response' };
        return this.intercept<T>(this.http.patch<T>(url, body, options)).toPromise();
    }

    private mergeHeaders(headers: HttpHeaders): HttpHeaders {
        let mergedHeaders = new HttpHeaders(this.defaultHeaders.keys().reduce((acc, key) => {
            acc[key] = this.defaultHeaders.get(key) as string;
            return acc;
        }, {} as { [name: string]: string | string[] }));

        headers?.keys().forEach(key => {
            mergedHeaders = mergedHeaders.set(key, headers.get(key) as string);
        });

        return mergedHeaders;
    }

    private intercept<T>(observable: Observable<HttpResponse<T>>): Observable<HttpResponse<T>> {
        return observable.pipe(catchError((err: HttpErrorResponse) => {
            const urlParts = err && err.url ? err.url.split('/'): [];
            const urlAction = urlParts && urlParts.length > 0? urlParts[urlParts.length - 1].split('?')[0]: '';

            if (err.status === 401 && urlAction !== 'login' && urlAction !== 'plugins') {
                localStorage.clear();
                this.router.navigate(['/login']);
                return EMPTY;
            } else if (err.status === 404) {
                this._ui.openSnackBar('Error 404: missing API endpoint');
                return throwError(() => err);
            } else {
                // Rewrite the error object because err.error and err.message are read-only
                let errorObject: any;

                if (err.error && typeof err.error === 'string') {
                    try {
                        errorObject = JSON.parse(err.error);
                    } catch (ex) {
                        errorObject = JSON.parse(JSON.stringify(err.error));
                    }
                } else {
                    errorObject = err.error;
                }

                const modifiedError = {
                    ...err,
                    error: errorObject
                };
                return throwError(() => modifiedError);
            }
        }));
    }
}
